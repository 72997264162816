import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import styled from 'styled-components';

export default function DeleteEmployeeModal({name, yes, no}) {
    return (
        <Modal show={true} onHide={no} centered>
            <ModalContainer>
                <Modal.Header>
                    Are you sure you want to delete {name}?
                </Modal.Header>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button style={{marginRight: '15px'}} variant="secondary" onClick={no}>No</Button>
                        <Button variant="danger" onClick={yes}>Yes</Button>
                    </ButtonGroup>
                </Modal.Footer>
            </ModalContainer>
        </Modal>
    )
}

const ModalContainer = styled.div`
    .btn {
        margin-right: 15px;
        width: 60px;
        border-top-right-radius: 5px !important;
        border-bottom-right-radius: 5px !important;
        border-top-left-radius: 5px !important;
        border-bottom-left-radius: 5px !important;
    }
`;
