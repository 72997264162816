import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUnlock, faLock } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/esm/Container';

const INTEGRATION_SETTINGS_URL = '/settings/integration';
const TERMINAL_URL = '/terminals';

export default function IntegrationSettings({currentShopSettings, refreshCurrentShopSettings}) {

    const [integrationSettings, setIntegrationSettings] = useState(currentShopSettings);
    const [terminals, setTerminals] = useState([]);
    const [unlockView, setUnlockView] = useState('');
    const [employeeUnlock, setEmployeeUnlock] = useState(true);
    const [disableSubmit, setDisableSubmit] = useState(false);

    useEffect(() => {
        setIntegrationSettings(currentShopSettings);
    }, [currentShopSettings]);

    useEffect(() => {
        axios.get(TERMINAL_URL).then(({ data }) => {
            setTerminals(data);
        }).catch(console.error)
    }, []);
    

    let updateIntegrationSettings = (key, value) => {
        let newShop = { ...integrationSettings };
        if (key === 'inventoryManagement' && value === 'blaze' && !integrationSettings.blaze) newShop.blaze = {};
        if (key === 'inventoryManagement' && value === 'treez' && !integrationSettings.treez) newShop.treez = {};
        newShop[key] = value;
        setIntegrationSettings(newShop);
    }

    let updateBlaze = (key, value) => {
        let newBlaze = { ...integrationSettings.blaze };
        newBlaze[key] = value;
        updateIntegrationSettings("blaze", newBlaze);
    }

    let updateBlazeTerminalMapping = (greenstopTerminalId, blazeTerminalId) => {
        let mapping = integrationSettings.blaze.greenstopToBlazeTerminalMap ? integrationSettings.blaze.greenstopToBlazeTerminalMap : {};
        mapping[greenstopTerminalId] = blazeTerminalId;
        updateBlaze("greenstopToBlazeTerminalMap", mapping);
    }

    const saveIntegrationSettings = () => {
        setDisableSubmit(true);
        
        axios.put(INTEGRATION_SETTINGS_URL, integrationSettings)
            .then(_ => {
                toast.success('Shop Info Setting Saved!')
                refreshCurrentShopSettings()
            })
            .catch(e => {
                console.error(e)
                refreshCurrentShopSettings()
            })
            .then(_ => setDisableSubmit(false));
    };

    return (
        <Container className='mt-3'>
            <h1>{integrationSettings.inventoryManagement?.toUpperCase()} Integration Settings</h1>
            <hr />
            <RedZone>
                <h2>Danger Zone {employeeUnlock ? <span style={{ float: 'right', color: '#26A65B' }} onClick={() => { setEmployeeUnlock(false); setUnlockView("locked"); }}><FontAwesomeIcon icon={faLock} /></span> : <span style={{ float: 'right', color: '#26A65B' }} onClick={() => { setEmployeeUnlock(true); setUnlockView("unlocked"); }}><FontAwesomeIcon icon={faUnlock} /></span>} </h2>
                { unlockView === "" || unlockView === "unlocked" ?
                    <>
                        { integrationSettings.inventoryManagement === "blaze" && !!integrationSettings.blaze &&
                            <div className="blazeTerminal">
                                <Form.Group controlId="authorization">
                                    <Form.Label>Authorization Key</Form.Label>
                                    <Form.Control disabled={employeeUnlock} required type="text" placeholder="Authorization" value={integrationSettings.blaze.authorization || ''} onChange={e => updateBlaze(e.target.id, e.target.value)} />
                                </Form.Group>
                                { terminals.sort((a, b) => a._id < b._id ? -1 : 1).map(terminal =>
                                    <div key={terminal._id}>
                                        <Form.Group key={terminal._id} controlId={terminal._id}>
                                            <Form.Label>{terminal.name}</Form.Label>
                                            <Form.Control disabled={employeeUnlock} required type="text" placeholder='blaze terminal id' value={(integrationSettings.blaze.greenstopToBlazeTerminalMap || {})[terminal._id] || ''} onChange={e => updateBlazeTerminalMapping(terminal._id, e.target.value)} />
                                        </Form.Group>
                                    </div>
                                )}
                            </div>
                        }
                        { integrationSettings.inventoryManagement === "treez" && !!integrationSettings.treez &&
                            <div className="treezConfig">
                                <Form.Group controlId="treezDispensaryName">
                                    <Form.Label>Treez Dispensary Name</Form.Label>
                                    <Form.Control disabled={employeeUnlock} required type="text" placeholder="treezDispensaryName" value={integrationSettings.treez.treezDispensaryName || ''} onChange={e => updateIntegrationSettings('treez', {treezDispensaryName: e.target.value})} />
                                </Form.Group>
                            </div>
                        }
                        { integrationSettings.inventoryManagement === "greenstop" &&
                            <Form.Group controlId="metrcLicenseNumber">
                                <Form.Label>METRC License Number</Form.Label>
                                <Form.Control type="text" placeholder="Shop METRC License Number" value={integrationSettings.metrcLicenseNumber} onChange={e => updateIntegrationSettings(e.target.id, e.target.value)} />
                            </Form.Group>
                        }
                        <Button disabled={disableSubmit || employeeUnlock} variant="primary" onClick={saveIntegrationSettings}>Save changes</Button>
                    </>
                    :
                    <div className="areSure">
                        <h2>Are you sure you want to change this?</h2>
                        <span className="yesLocked" onClick={() => { setEmployeeUnlock(false); setUnlockView("unlocked"); }}>Yes</span>
                        <span className="notLocked" onClick={() => { setEmployeeUnlock(true); setUnlockView("unlocked"); }}>No</span>
                    </div>
                }
            </RedZone>
        </Container>
    )

}


const RedZone = styled.div`
    padding: 10px;
    border: 2px solid red;
    h2 {
        color: red;
        text-align: left;
        font-size: 18px;
    }
    label {
        color: red;
        margin-right: 20px;
    }
    select {
        vertical-align: top;
        margin-top: 5px;
    }
    .areSure {
        margin-bottom: 20px;
        h2 {
            text-align: center;
            margin-bottom: 20px;
        }
        .yesLocked {
            margin-right: 20px;
            padding: 10px 20px;
            border-radius: 5px;
            border: 1px solid #26A65B;
            background: #26A65B;
            color: white;
        }
        .yesLocked:hover {
            cursor: pointer;
            background: white;
            color: #26A65B;
        }
        .notLocked {
            padding: 10px 20px;
            border-radius: 5px;
            border: 1px solid red;
            color: red;
        }
        .notLocked:hover {
            cursor: pointer;
        }
    }
    .form-group label{
        width: 20% !important;
        display: inline-block;
        text-align: left;
        font-weight: bold;
    }
    .form-group input{
        width: 60%;
        display: inline-block;
    }
    .form-group select{
        width: 60%;
        display: inline-block;
    }
`;
