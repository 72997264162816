import React from 'react';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SalesByTimeIntervalChart from './sales/salesByTimeIntervalChart';
import SalesSummaryHome from './sales/salesSummaryHome';
import useOrdersAnalytics from '../hooks/useOrdersAnalytics';
import Loading from './loading';
import NoSales from './sales/noSales';

export default function HomeSalesDisplay({ ordersDateParameters }) {
    const [salesSummary] = useOrdersAnalytics(ordersDateParameters, 'salesSummary')

    if (!salesSummary) return <Loading></Loading>

    if (salesSummary.length === 0) {
        return (
            <Col md={12}>
                <Card>
                    <Link className="font-weight-bold" to="/sales">
                        <Card.Header style={{ fontSize: '2rem' }} className="text-primary">Sales - {new Date().toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' })}</Card.Header>
                    </Link>
                    <Card.Body className="pb-0">
                        <NoSales message={"No Sales Data Exists for Today"} />
                    </Card.Body>
                </Card>
            </Col>)
    }
    else {
        return (
            <Col md={12}>
                <Card>
                    <Link className="font-weight-bold" to="/sales">
                        <Card.Header style={{ fontSize: '2rem' }} className="text-primary">Sales - {new Date().toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' })}</Card.Header>
                    </Link>
                    <Card.Body className="pb-0">
                        <Row style={{ right: '-100px' }} className="mt-0 pt-1">
                            <Col md={9} className="m-0 p-0">
                                <SalesByTimeIntervalChart ordersDateParameters={ordersDateParameters} />
                            </Col>
                            <Col md={3} className="m-0 pl-0 pt-0" >
                                <SalesSummaryHome ordersDateParameters={ordersDateParameters} />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>)
    }
}