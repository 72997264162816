import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Loading from './loading';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import styled from 'styled-components';
import { DateTime } from 'luxon';

const TERMINALS_URL = '/terminals';

export default function KioskManagement() {

    const [terminals, setTerminals] = useState(null);

    useEffect(() => {
        axios.get(TERMINALS_URL).then(({ data }) => {
            data.forEach(terminal => {
                let inventory = new Array(terminal.maxProducts);
                inventory.fill({});
                terminal.inventory = terminal.products.reduce((out, item) => {
                    out[item.slot] = item;
                    return out;
                }, inventory);
            })
            data.sort((t1, t2) => {
                if (t1._id < t2._id) { return -1; }
                if (t1._id > t2._id) { return 1; }
                return 0;
            })
            if (data && data.length) {
                setTerminals(data);
            } else {
                setTerminals([]);
            }
        })
    }, []);

    if (!terminals) return <Loading />

    let kioskTerminalMap = terminals.reduce((terminalMap, terminal) => {
        let kioskId = terminal._id.split('-')[1] || terminal._id;

        terminalMap[kioskId] = (terminalMap[kioskId] || []).concat(terminal);
        return terminalMap;
    }, {});
    


    let terminalRows = Object.keys(kioskTerminalMap).sort().map((kioskId, kioskIndex) => {
        let terminalRows = kioskTerminalMap[kioskId].map(terminal => {
            let numInStock = terminal.inventory.reduce((count, inventoryItem) => inventoryItem.stock ? count + 1 : count, 0);
            let lowStock = numInStock <= terminal.maxProducts / 2;
            let paymentOptions = [];
            if (terminal.paymentOptions) {
                paymentOptions = Object.entries(terminal.paymentOptions).map(([key, value]) => {
                    return (<Badge variant={value ? 'success' : 'danger'} className="text-capitalize mx-1" key={key}>{key}</Badge>
                    )
                })
            }
            const fiveMinutesAgo = DateTime.now().minus({minutes: 5})
            let connected = DateTime.fromJSDate(new Date(terminal.statusUpdated)) >  fiveMinutesAgo;

            let healthCheck = connected;

            let notifications = (
                <div className='' style={{ paddingTop: '0px !important' }}>
                    <h6 className={connected ? 'text-success' : 'text-danger'}>{DateTime.fromJSDate(new Date(terminal.statusUpdated)).toLocaleString({...DateTime.DATETIME_SHORT, year: undefined})}</h6>
                    {lowStock ?
                        numInStock ?
                            <h6 className='text-warning'>Low Stock</h6>
                            : <h6 className='text-danger'>Out of Stock</h6>
                        : null
                    }
                    {terminal.billAcceptorInfo ?
                        <div>
                            <h5>
                                Cashbox: ${terminal.billAcceptorInfo.stackerCash || 0}
                            </h5>
                            {terminal.billAcceptorInfo.recycler ? <h6>
                                Recycler: <span className={terminal.billAcceptorInfo.recycler.fives <= 3 && terminal.billAcceptorInfo.recycler.ones <= 3 ? 'text-danger' : 'text-primary'}>
                                    { terminal.billAcceptorInfo.recycler.fives || terminal.billAcceptorInfo.recycler.ones }
                                </span>/30
                            </h6> : null}
                            {terminal.billAcceptorInfo.stacker ?
                                <h6>
                                    Stacker: <span className={terminal.billAcceptorInfo.stacker.full ? 'text-danger' : 'text-primary'}>
                                        {terminal.billAcceptorInfo.stacker.numBills}
                                    </span>/500
                                </h6> : null
                            }
                        </div> : null
                    }
                    { paymentOptions }
                </div>
            );

            let inventoryItemCards = terminal.inventory.map((item, idx) => (
                <div key={idx} style={{ display: 'inline-block', float: 'none', width: '280px', padding: '10px' }}>
                    <Card>
                        <Card.Header style={{ backgroundColor: item.stock === 0 ? 'yellow' : '#26A65B', color: item.stock === 0 ? '#26A65B' : 'white' }} className={item.name ? 'tileHeader' : 'bg-secondary text-light'}>
                            <OverlayTrigger placement="top" delay={{ show: 400, hide: 400 }} overlay={<Tooltip>{item.name || 'No product set for this slot'}</Tooltip>}>
                                <h4 className="ml-2" style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{(item.name || 'EMPTY')}</h4>
                            </OverlayTrigger>
                        </Card.Header>
                        {/* <h4 style={{ position: 'absolute', top: '5px', left:'10px', color: item.stock === 0 ? '#26A65B' : 'white', fontWeight: 'bold', }}>{String.fromCharCode(65+idx)}</h4> */}
                        <Card.Body>
                            {/* <img style={{...halfStyle, height: '100px'}} src={item.img}/> */}
                            <div>Slot: {String.fromCharCode(65+idx)}</div>
                            <div>Stock: {item.stock}</div>
                            {item.restocked && <small>Stocked: {DateTime.fromJSDate(new Date(item.restocked)).toLocaleString({...DateTime.DATETIME_SHORT, year: undefined})} </small>}
                            {item.recomended && <Badge variant="info">Recommended</Badge> }
                        </Card.Body>
                    </Card>
                </div>
            ))

            return (
                <Card key={terminal._id} style={{ borderTop: '1px solid #0c493116' }}>
                    <Row className='mt-0'>
                        <Col className="terminalName" sm='2' style={{ marginRight: '-15px', paddingTop: '15px', }}>
                            <a href={`/#/kiosks/${terminal._id}`} >
                                <h3 className={`bg-primary pt-2 pb-2${!healthCheck ? ' text-danger' : ''}`} style={{ borderRadius: '0px 25px 25px 0px' }}>
                                    {terminal.name}
                                </h3>
                            </a>
                            <h2 className='mt-1'>
                                <span className={lowStock ? 'text-danger' : 'text-primary'}>{numInStock}</span>/{terminal.maxProducts}
                            </h2>
                            {terminal.currentOrderId && <a href={`/#/openOrders/${terminal.currentOrderId}`}>Order</a>}
                        </Col>

                        <Col sm='3' xl='2'className='bg-light' style={{ borderLeft: '1px solid lightgrey', borderRight: '1px solid lightgrey', paddingTop: '20px' }}>
                            {notifications}
                        </Col>
                        <Col sm='7' xl='8' className='scrollBox pt-0'>
                            <div style={scrollableRow}>
                                {inventoryItemCards}
                            </div>
                        </Col>

                    </Row>
                </Card>
            )
        });
        return (<Card style={{ borderLeft: '1px solid green' }} className="mb-3" key={kioskId}>
            <Card.Header >{kioskTerminalMap[kioskId][0].kioskName || 'Kiosk ' + kioskIndex}</Card.Header>
            {terminalRows}
        </Card>)
    });

    return (
        <>
            {
                terminals.length ?
                    <KioskContainer className='kiosk-management p-5'>
                        {terminalRows}
                    </KioskContainer> :
                    <h2>There are no kiosks</h2>
            }
        </>
    )
}

const scrollableRow = {
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    display: 'block',
    padding: '5px',
    margin: 0
}

const KioskContainer = styled.div`
    .row > div { 
        border-left: 0 !important;
        border-right: 0 !important;
    }
    .card {
        border: 0;
    }
    .scrollBox {
        padding-right: 6px;
        box-shadow:inset -12px 0 14px -12px rgba(0,0,0,0.7);
    }
    .terminalName {
        h3 {
            margin-right: -15px;
            color: #26A65B;
            font-size: 20px;
            border-radius: 0 !important;
            margin: 0 auto;
            background-color: #FFFFFF !important;
        }
    }
    .card-header {
        border-bottom: 0;
        background-color: #26A65B;
        color: #FFFFFF;
        font-size: 21px;
        h4 {
            font-size: 16px;
            margin-bottom: 0;
        }
    }
    @media (max-width: 575px) {
        .terminalName {
            h2 {
                margin-top: 0px !important;
            }
        }
    }
`;
