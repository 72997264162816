import useCategories from './useCategories';

export default function useCategoryMap() {
    const [categories, reloadCategories] = useCategories();

    const categoryMap = categories && categories.reduce((categoriesMap, category) => {
        categoriesMap[category.key] = category;
        return categoriesMap;
    }, {});

    return [categoryMap, reloadCategories];
}
