import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const ALL_PACKAGES_URL = '/packages/all'
const INCOMING_PACKAGES_URL = '/packages/incoming'
const ACTIVE_PACKAGES_URL = '/packages/active'
const EMPTY_PACKAGES_URL = '/packages/empty'

const PACKAGES_URL_MAP = {
    incoming: INCOMING_PACKAGES_URL,
    all: ALL_PACKAGES_URL,
    active: ACTIVE_PACKAGES_URL,
    empty: EMPTY_PACKAGES_URL
}

export default function usePackages(category = 'all') {
    const [packages, setPackages] = useState([]);

    const [reload, setReload] = useState(false);
    const reloadPackages = useCallback(_ => setReload(!reload), [reload, setReload]);

    useEffect(() => {
        axios.get(PACKAGES_URL_MAP[category]).then(({data}) => {
            console.log('usePackages: ', data)
            setPackages(data);
        }).catch(console.error)
    }, [reload, category])

    return [packages, reloadPackages];
}
