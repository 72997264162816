import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList } from 'recharts';
import useOrdersAnalytics from '../../hooks/useOrdersAnalytics';
import Loading from '../loading';

export default function TopFiveProducts({orders ,ordersDateParameters}) {

    const [topFiveProducts] = useOrdersAnalytics(ordersDateParameters, 'topFiveProducts')

    if(!topFiveProducts) return <Loading/>;

    return (
        <ResponsiveContainer height={450} width="90%" className='mt-0 pt-0' >
            <BarChart layout="vertical" width="50%" height={400} data={topFiveProducts} >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" label="Quantity Sold" dataKey="value" allowDecimals={false} textAnchor="end" angle={-45} height={80} />
                <YAxis type="category" label={{ value: "Product Name", angle: -90, position: 'insideLeft' }} tick={false} dataKey="name" />
                <Tooltip />
                <Bar dataKey="value" name="Quantity Sold" fill="#82ca9d" >
                    <LabelList dataKey="name" position="insideLeft" />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    )
}