import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/esm/Row';

export default function SalesSummaryCustomer({ orders }) {
    const countSales = orders.length;
    const totalSales = orders.reduce((totalSales, current) => current["total"] + totalSales, 0);
    const averageSale = totalSales / countSales;
    
    return (
        <Row className="mt-0 ml-1 mr-1 pt-0">
            <Col lg={4} className="pt-0 pl-2 pr-2">
                <Card>
                    <h1 style={{ color: '#26A65B', }}>Visits: </h1>
                    <h2>{countSales}</h2>
                </Card>
            </Col>
            <Col lg={4} className="pt-0 pl-2 pr-2">
                <Card>
                    <h1 style={{ color: '#26A65B', }}>Total Spent: </h1>
                    <h2>${totalSales.toFixed(2)}</h2>
                </Card>
            </Col>
            <Col lg={4} className="pt-0 pl-2 pr-2">
                <Card>
                    <h1 style={{ color: '#26A65B', }}>Avg Spent: </h1>
                    <h2>${averageSale.toFixed(2)}</h2>
                </Card>
            </Col>
        </Row>)
}

