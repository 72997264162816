import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import axios from 'axios';
import { toast } from 'react-toastify';

const TAX_SETTINGS_URL = '/settings/taxes';

export default function TaxSettings({currentShopSettings, refreshCurrentShopSettings}) {

    const [taxTypes, setTaxTypes] = useState([]);
    const [disableSubmit, setDisableSubmit] = useState(false);

    useEffect(_ => {
        setTaxTypes(currentShopSettings.taxTypes);
    }, [currentShopSettings]);


    let updateTaxType = (idx, property, value) => {
        let newTaxType = taxTypes[idx] || {};
        newTaxType[property] = value;
        let newTaxTypes = [...taxTypes];
        newTaxTypes[idx] = newTaxType;
        setTaxTypes(newTaxTypes);
    }

    let addTaxType = _ => {
        setTaxTypes(taxTypes.concat([{}]))
    }

    let deleteTaxType = idx => {
        let newTaxTypes = [...taxTypes];
        newTaxTypes.splice(idx, 1);
        setTaxTypes(newTaxTypes);
    }

    const saveTaxSettings = () => {
        setDisableSubmit(true);
        axios.put(TAX_SETTINGS_URL, taxTypes.filter(t => t && t.name && t.rate))
            .then(_ => {
                toast.success('Tax Setting Saved!')
                refreshCurrentShopSettings()
            })
            .catch(e => {
                console.error(e)
                refreshCurrentShopSettings()
            })
            .then(_ => setDisableSubmit(false));
    };

    return (
        <Container>
            <h1>Update Taxes</h1>
            <Form>
                <TaxContainer>
                    <Form.Row>
                        <Form.Label>Tax Types</Form.Label>
                        <Button className="addBtn" onClick={addTaxType}>Add new Tax type</Button>
                        {
                            taxTypes.map((taxType, idx) =>
                                <div className="taxInfo" key={idx}>
                                    <Form.Group>
                                        <Form.Label>Name: </Form.Label>
                                        <Form.Control required value={taxType.name || ''} onChange={e => updateTaxType(idx, 'name', e.target.value)} />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Rate: </Form.Label>
                                        <Form.Control required type='number' step={0.01} value={typeof taxType.rate === 'number' ? taxType.rate : ''} onChange={e => updateTaxType(idx, 'rate', parseFloat(e.target.value))} />
                                    </Form.Group>
                                    <Button className="deleteBtn" onClick={_ => deleteTaxType(idx)}>Delete</Button>
                                </div>
                            )
                        }
                    </Form.Row>
                </TaxContainer>
                <Button disabled={disableSubmit} variant="primary" onClick={saveTaxSettings}>Save changes</Button>
            </Form>
        </Container>
    )
}

const Container = styled.div`
    width: 75%;
    justify-content: center;
    margin: 0 auto;
    padding-bottom: 30px;
    h1 {
        margin-bottom: 45px;
    }
    button {
        margin-top: 45px;
    }
    .form-group {
        .input-group {
            width: 60%;
            display: inline-block;
        }
        .input-group-append {
            width: auto;
            display: inline-block;
        }
    }
    .form-group label{
        width: 20% !important;
        display: inline-block;
        text-align: left;
        font-weight: bold;
    }
    .form-group input{
        width: 60%;
        display: inline-block;
    }
    .form-group select{
        width: 60%;
        display: inline-block;
    }
    .form-row {
        width: 80%;
        margin: 0 auto;
        justify-content: space-between;
        .col {
            width: auto;
            flex-basis: auto;
            flex-grow: 0;
            text-align: left;
            padding-left: 0;
            padding-right: 0;
            input {
                width: 80%;
            }
        }
        .state {
            select {
                width: 80%;
                display: inline-block;
            }
            width: 40%;
        }
    }
    .form-check {
        color: #000000;
        margin-bottom: 15px;
        margin-right: 15px;
        input {
            position: relative;
            width: auto;
        }
        label {
            width: auto !important;
        }
    }
    .blazeTerminal {
        width: 80%;
        margin: 0 auto;
        .form-group label{
            width: 40% !important;
            display: inline-block;
            text-align: left;
            font-weight: bold;
        }
        .form-group input{
            width: 40%;
            display: inline-block;
        }
    }
    .treezConfig {
        width: 80%;
        margin: 0 auto;
        .form-group label{
            width: 40% !important;
            display: inline-block;
            text-align: left;
            font-weight: bold;
        }
        .form-group input{
            width: 40%;
            display: inline-block;
        }
    }
    .employeeForm {
        h2 {
            font-size: 18px;
            text-align: left;
            margin-left: 10%;
        }
        .permissions {
            width: 80%;
            margin: 0 auto;
            margin-top: 15px;
            text-align: left;
        }
    }
`;

const TaxContainer = styled.div`
    display: inline-block;
    justify-content: space-between;
    width: 100%;
    .form-row label{
        width: 20% !important;
        display: inline-block;
        text-align: left;
        font-weight: bold;
    }
    .addBtn {
        margin-bottom: 25px !important;
    }
    .form-row button{
        margin-top: 0;
        width: 200px;
    }
    .taxInfo {
        width: 100%;
        display: block;
    }
    .deleteBtn {
        margin-right: 10%;
        float: right;
        margin-bottom: 45px !important;
    }

`;
