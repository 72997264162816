import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTable } from 'react-table';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { DateTime } from 'luxon';
import Loading from './loading';
import Container from 'react-bootstrap/Container';


const PREORDERS_URL = '/preorders';

const columns = [
    {
      Header: 'Preorder Details',
      id: 'preorderDetails',
      Cell: p => <Button href={`#/preorders/${p.row.original._id}`}>Details</Button>,
    },
    {
      Header: 'Time',
      accessor: p => DateTime.fromISO(p.createdDate).toLocaleString(DateTime.TIME_SIMPLE),
    },
    {
      Header: 'Email',
      id: 'email',
      accessor: p => p.email
    },
    {
      Header: 'DLN',
      id: 'driversLicenseNumber',
      accessor: p => p.driversLicenseNumber
    },
    {
      Header: 'Num Items',
      id: 'numItems',
      accessor: p => p.items.length
    },
    {
      Header: 'SubTotal',
      id: 'subTotal',
      accessor: p => p.items.reduce((total, item) => total + item.price*item.quantity, 0)
    }
]

export default function Preorders(props) {
    const [preorders, setPreorders] = useState(null);

    useEffect(() => {
        axios.get(PREORDERS_URL)
            .then(({ data }) => setPreorders(data || []))
            .catch(_ => setPreorders([]));
    }, [])


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows
    } = useTable({ columns, data: preorders || []})

    if (!preorders) return <Loading/>
    return preorders.length ? <Container>
        <Breadcrumb className="mt-3">
            <Breadcrumb.Item active>Preorders</Breadcrumb.Item>
        </Breadcrumb>
        <Table {...getTableProps()} style={{ margin: 'auto' }}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr className='rt-tr' {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        <th className='rt-thead' {...column.getHeaderProps()} >
                        {column.render('Header')}
                        </th>
                    ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row)
                    return (
                    <tr className='rt-tr' {...row.getRowProps()}>
                        {row.cells.map(cell => {
                        return (
                            <td
                            {...cell.getCellProps()}
                            >
                            {cell.render('Cell')}
                            </td>
                        )
                        })}
                    </tr>
                    )
                })}
                </tbody>
            </Table>
    </Container> : <h2>No Preorders Found</h2>
}
