import React, { useState, useEffect, useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { toast } from 'react-toastify';


const ONLINE_STORE_SETTINGS_URL = '/settings/onlineStore';
const IMAGE_URL = '/asset';

export default function OnlineStoreSettings({currentShopSettings, refreshCurrentShopSettings}) {

    const [onlineStoreSettings, setOnlineStoreSettings] = useState(currentShopSettings);
    const [disableSubmit, setDisableSubmit] = useState(false);


    const [imagePreview, setImagePreview] = useState(null);
    const onDrop = useCallback(acceptedFiles => {
        setDisableSubmit(true)
        let file = acceptedFiles[0];
        setImagePreview(URL.createObjectURL(file));

        let imgBody = new FormData();
        imgBody.append('productImage', file)
        axios.post(IMAGE_URL, imgBody , {headers: { 'content-type': 'multipart/form-data' }})
            .then(({data}) => setOnlineStoreSettings({ ...onlineStoreSettings, img: data.Location }))
            .catch(e => console.error('Error Uploading Image: ', e))
            .then(_ => setDisableSubmit(false));
    }, [onlineStoreSettings]);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'image/*' })

    const [bannerPreview, setBannerPreview] = useState(null);
    const onDropBanner = useCallback(acceptedFiles => {
        setDisableSubmit(true)
        let file = acceptedFiles[0];
        setBannerPreview(URL.createObjectURL(file));

        let imgBody = new FormData();
        imgBody.append('productImage', file)
        axios.post(IMAGE_URL, imgBody , {headers: { 'content-type': 'multipart/form-data' }})
            .then(({data}) => setOnlineStoreSettings({ ...onlineStoreSettings, banner: data.Location }))
            .catch(e => console.error('Error Uploading Image: ', e))
            .then(_ => setDisableSubmit(false));
    }, [onlineStoreSettings]);
    const { getRootProps: getBannerRootProps, getInputProps: getBannerInputProps, isDragActive: isBannerDragActive } = useDropzone({ onDrop: onDropBanner, accept: 'image/*' })

    useEffect(() => {
        currentShopSettings && currentShopSettings.img && setImagePreview(currentShopSettings.img);
        currentShopSettings && currentShopSettings.banner && setBannerPreview(currentShopSettings.banner);
        setOnlineStoreSettings(currentShopSettings);
    }, [currentShopSettings]);

    const saveOnlineStoreSettings = () => {
        setDisableSubmit(true);
        axios.put(ONLINE_STORE_SETTINGS_URL, onlineStoreSettings)
            .then(_ => {
                toast.success('Online Store Setting Saved!')
                refreshCurrentShopSettings()
            })
            .catch(e => {
                console.error(e)
                toast.error('Error Saving Online Store Settings')
                refreshCurrentShopSettings()
            })
            .then(_ => setDisableSubmit(false));
    };

    return (
        <Container>
            <h1 className='my-3'>Online Store Settings</h1>
            <Form>
                <Form.Group controlId="allowPreorder">
                    <Form.Check>
                        <Form.Check.Input checked={onlineStoreSettings.allowPreorder} type='checkbox' onChange={e => setOnlineStoreSettings({ ...onlineStoreSettings, allowPreorder: e.target.checked, allowOnlineMenu: onlineStoreSettings.allowOnlineMenu || e.target.checked})}/>
                        <Form.Check.Label>Allow Preorders</Form.Check.Label>
                    </Form.Check>
                </Form.Group>
                <Form.Group controlId="allowOnlineMenu">
                    <Form.Check>
                        <Form.Check.Input disabled={onlineStoreSettings.allowPreorder} checked={onlineStoreSettings.allowOnlineMenu} type='checkbox' onChange={e => setOnlineStoreSettings({...onlineStoreSettings, allowOnlineMenu: e.target.checked})}/>
                        <Form.Check.Label>Allow Online Menu</Form.Check.Label>
                    </Form.Check>
                </Form.Group>
                <Form.Group controlId="image">
                    <Form.Label>Online Store Images:</Form.Label>
                    <Form.Row>
                        <Col md="6" className="dropzone" {...getRootProps()}>
                            <input {...getInputProps()} />
                            {
                                isDragActive ?
                                    <p>Drop image here ...</p> :
                                    <div>
                                        {!imagePreview ? <p>Upload Shop Image Here</p>
                                            : <img className='dropzone-preview' alt="uploaded" src={imagePreview} />}
                                    </div>
                            }
                        </Col>
                        { getBannerRootProps && <Col md="6" className="dropzone" {...getBannerRootProps()}>
                            <input {...getBannerInputProps()} />
                            {
                                isBannerDragActive ?
                                    <p>Drop image here ...</p> :
                                    <div>
                                        {!bannerPreview ? <p>Upload Banner Image Here</p>
                                            : <img className='dropzone-preview' alt="uploaded" src={bannerPreview} />}
                                    </div>
                            }
                        </Col>}
                    </Form.Row>
                </Form.Group>
                <Button disabled={disableSubmit} variant="primary" onClick={saveOnlineStoreSettings}>Save changes</Button>
            </Form>
        </Container>
    )
}
