import { DateTime } from 'luxon';

export default function getOrdersDatesParams(dateParameter = 'day') {
    let ordersDates;

    switch (dateParameter) {
        case 'day':
            ordersDates = { "startDate": DateTime.local().startOf('day').toISO(), "stopDate": DateTime.local().endOf('day').toISO() }
            break
        case 'week':
            ordersDates = { "startDate": DateTime.local().startOf('week').toISO(), "stopDate": DateTime.local().endOf('week').toISO() }
            break
        case 'month':
            ordersDates = { "startDate": DateTime.local().startOf('month').toISO(), "stopDate": DateTime.local().endOf('month').toISO() }
            break
        default:
            ordersDates = {"startDate" : DateTime.fromJSDate(dateParameter.startDate).startOf('day').toISO(), "stopDate" : DateTime.fromJSDate(dateParameter.stopDate).endOf('day').toISO()}
            break

    }

    return ordersDates;
}