import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const PRODUCT_URL = '/products';

export default function useProducts(category) {
    const [products, setProducts] = useState([]);

    const [reload, setReload] = useState(false);
    const reloadProducts = useCallback(_ => setReload(!reload), [reload, setReload]);


    useEffect(() => {
        let url = PRODUCT_URL;
        url += category ? '/' + category : ''; 
        axios.get(url).then(({data}) => {
            console.log('useProducts: ', data)
            setProducts(data);
        }).catch(console.error)
    }, [reload, category])

    return [products, reloadProducts];
}
