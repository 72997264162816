import React, { useCallback, useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { useDropzone } from 'react-dropzone';
import useCategories from '../../hooks/useCategories';
import axios from 'axios';
import styled from 'styled-components';
import Loading from '../loading';
import useShop from '../../hooks/useShop';

const PRODUCT_URL = '/product';

const IMAGE_URL = '/asset';

const UNIT_OPTIONS = ['grams', 'each'].map(opt => <option key={opt} value={opt}>{opt}</option>)

const CANNABIS_OPTIONS = ['---', 'indica', 'sativa', 'hybrid'].map(opt => <option key={opt} value={opt}>{opt}</option>)

export default function EditProductModal({ afterSave, hide, productIn }) {

    const [product, setProduct] = useState(productIn);
    const [imagePreview, setImagePreview] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [validated, setValidated] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [shop] = useShop();
    const [price, setPrice] = useState(productIn.price ? productIn.price : 0);
    const [categories] = useCategories();

    const updateProduct = useCallback((key, value) => {
        let newProduct = { ...product };
        newProduct[key] = value;
        setProduct(newProduct);
    }, [setProduct, product])

    const saveProduct = useCallback(product => {
        if(!product['unit']) product['unit'] = 'each';
        axios.put(PRODUCT_URL, product).then(({ data }) => {
            afterSave(data);
            setDisabled(false);
        }).catch(error => error);
    }, [afterSave]);

    const uploadImageAndSave = useCallback((event) => {
        setDisabled(true);
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);

        if (imageFile) {
            let imgBody = new FormData();
            imgBody.append('productImage', imageFile)
            axios.post(IMAGE_URL, imgBody , {headers: { 'content-type': 'multipart/form-data' }})
                .then(({data}) => {
                    saveProduct({ ...product, img: data.Location });
                }).catch(e => console.error('Error Uploading Product Image: ', e));

        } else {
            saveProduct(product);
        }
    }, [imageFile, product, saveProduct])

    const onDrop = useCallback(acceptedFiles => {
        let file = acceptedFiles[0];
        setImageFile(file);
        setImagePreview(URL.createObjectURL(file));
    }, [setImageFile, setImagePreview]);

    useEffect(_ => {
        productIn && productIn.img && setImagePreview(productIn.img);
    }, [productIn]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'image/*' })

    if (!categories || !shop) return <Loading />

    let categoryOptions = categories.map(opt => <option key={opt.key} value={opt.key}>{opt.label}</option>);

    let generateCost = 0;

    if (price > 0 && shop && shop.taxTypes && shop.taxTypes.length > 0) {
        shop.taxTypes.forEach(type => generateCost += price * type.rate)
        generateCost += price;
    } else {
        generateCost = price;
    }

    return (
        <Modal show={!!product} onHide={hide}>
            <Modal.Header closeButton>
                <Modal.Title>{product._id ? 'Edit' : 'Create'} Product:</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form validated={validated} onSubmit={uploadImageAndSave}>
                    <Form.Group controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control required type="text" placeholder="Product Name" value={product.name || ''} onChange={e => updateProduct(e.target.id, e.target.value)} />
                    </Form.Group>
                    <Form.Group controlId="brand">
                        <Form.Label>Brand</Form.Label>
                        <Form.Control type="text" placeholder="(Optional)" value={product.brand || ''} onChange={e => updateProduct(e.target.id, e.target.value)} />
                    </Form.Group>
                    <Form.Group controlId="price">
                        <Form.Label>Price</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                            <Form.Control required type="number" value={product.price || ''} onChange={e => { updateProduct(e.target.id, parseFloat(e.target.value) || null); setPrice(parseFloat(e.target.value)); }} />
                        </InputGroup>
                    </Form.Group>
                    <TotalCost>
                        <p>Total price after tax:</p><p>${parseFloat(generateCost).toFixed(2)}</p>
                    </TotalCost>
                    <Form.Group controlId="cost">
                        <Form.Label>Cost</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                            <Form.Control type="number" placeholder="(Optional)" value={product.cost || ''} onChange={e => updateProduct(e.target.id, parseFloat(e.target.value) || null)} />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Amount</Form.Label>
                        <InputGroup>
                            <Form.Control required id="amount" type="number" value={product.amount} onChange={e => updateProduct('amount', e.target.value)} />
                            <InputGroup.Append>
                                <Form.Control id="unit" as="select" placeholder="unit" value={product.unit} onChange={e => updateProduct('unit', e.target.value)}>
                                    {UNIT_OPTIONS}
                                </Form.Control>
                            </InputGroup.Append>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group controlId="category">
                        <Form.Label>Category</Form.Label>
                        <Form.Control as="select" defaultValue={product.category ? product.category : ''} onChange={e => updateProduct(e.target.id, e.target.value)}>
                            {!product.category && <option key="" value={null}>---</option>}
                            {categoryOptions}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Cannabis Type</Form.Label>
                        <Form.Control id="cannabisType" as="select" placeholder="cannabisType" value={product.cannabisType} onChange={e => updateProduct('cannabisType', e.target.value)}>
                            {CANNABIS_OPTIONS}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="description">
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea" rows={4} value={product.description} onChange={e => updateProduct(e.target.id, e.target.value)} />
                    </Form.Group>
                    <Form.Group controlId="thc">
                        <Form.Label>THC %</Form.Label>
                        <InputGroup>
                            <Form.Control as="input" min="0" max="100" value={product.thc} onChange={e => updateProduct(e.target.id, e.target.value)} />
                            <InputGroup.Append><InputGroup.Text>%</InputGroup.Text></InputGroup.Append>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group controlId="cbda">
                        <Form.Label>CBDA %</Form.Label>
                        <InputGroup>
                            <Form.Control as="input" min="0" max="100" value={product.cbda} onChange={e => updateProduct(e.target.id, e.target.value)} />
                            <InputGroup.Append><InputGroup.Text>%</InputGroup.Text></InputGroup.Append>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group controlId="cbd">
                        <Form.Label>CBD %</Form.Label>
                        <InputGroup>
                            <Form.Control as="input" min="0" max="100" value={product.cbd} onChange={e => updateProduct(e.target.id, e.target.value)} />
                            <InputGroup.Append><InputGroup.Text>%</InputGroup.Text></InputGroup.Append>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group controlId="cbn">
                        <Form.Label>CBN %</Form.Label>
                        <InputGroup>
                            <Form.Control as="input" min="0" max="100" value={product.cbn} onChange={e => updateProduct(e.target.id, e.target.value)} />
                            <InputGroup.Append><InputGroup.Text>%</InputGroup.Text></InputGroup.Append>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group controlId="cbg">
                        <Form.Label>CBG %</Form.Label>
                        <InputGroup>
                            <Form.Control as="input" min="0" max="100" value={product.cbg} onChange={e => updateProduct(e.target.id, e.target.value)} />
                            <InputGroup.Append><InputGroup.Text>%</InputGroup.Text></InputGroup.Append>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group controlId="cbc">
                        <Form.Label>CBC %</Form.Label>
                        <InputGroup>
                            <Form.Control as="input" min="0" max="100" value={product.cbc} onChange={e => updateProduct(e.target.id, e.target.value)} />
                            <InputGroup.Append><InputGroup.Text>%</InputGroup.Text></InputGroup.Append>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group controlId="thcv">
                        <Form.Label>THCV %</Form.Label>
                        <InputGroup>
                            <Form.Control as="input" min="0" max="100" value={product.thcv} onChange={e => updateProduct(e.target.id, e.target.value)} />
                            <InputGroup.Append><InputGroup.Text>%</InputGroup.Text></InputGroup.Append>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group controlId="cbdv">
                        <Form.Label>CBDV %</Form.Label>
                        <InputGroup>
                            <Form.Control as="input" min="0" max="100" value={product.cbdv} onChange={e => updateProduct(e.target.id, e.target.value)} />
                            <InputGroup.Append><InputGroup.Text>%</InputGroup.Text></InputGroup.Append>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group controlId="dateTested">
                        <Form.Label>Date Tested</Form.Label>
                        <Form.Control type="date" as="input" value={product.dateTested} onChange={e => updateProduct(e.target.id, e.target.value)} />
                    </Form.Group>
                    <Form.Group controlId="image">
                        <Form.Label>Upload Image:</Form.Label>
                        <div className="dropzone" {...getRootProps()}>
                            <input {...getInputProps()} />
                            {
                                isDragActive ?
                                    <p>Drop image here ...</p> :
                                    <div>
                                        {!imagePreview ? <p>Upload Product Image Here</p>
                                            : <img className='dropzone-preview' alt="uploaded" src={imagePreview} />}
                                    </div>
                            }
                        </div>
                    </Form.Group>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={hide}>Cancel</Button>
                        <Button variant="primary" type="submit" disabled={disabled}>Save changes</Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

const TotalCost = styled.div`
    width: 100%;
    justify-content: space-between;
    display: flex;
    p {
        color: #26A65B;
        font-weight: bold;
    }

`;
