
export default function groupSalesByProductId(order) {
    const groupedProducts = order.items.reduce((aggregation, current) => {
        const side = (current.terminalId || current.locationId)?.split('-t')[1];
        if (current.productId in aggregation) {
            aggregation[current.productId].quantity += current.quantity;
            aggregation[current.productId].quantityVended += order.sidesVended[(current.terminalId || current.locationId)] ? current.quantity : 0
            aggregation[current.productId].sides.push(side);
        } else {
            aggregation[current.productId] = {...current, quantityVended: order.sidesVended && order.sidesVended[(current.terminalId || current.locationId)] ? current.quantity : 0, sides: [side]};
        }
        return aggregation;
    }, {});

    return Object.values(groupedProducts);
}