import React, { useState, useEffect } from 'react';
import getSalesInfoByTimeInterval from '../../common/salesInfoByTimeInterval';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import useOrdersAnalytics from '../../hooks/useOrdersAnalytics';
import Loading from '../loading';
import Card from 'react-bootstrap/Card';

const formatTime = f => f >= 12 ? `${(f-12) || 12}PM` : `${f}AM`;

export default function SalesByTimeIntervalChart({ ordersDateParameters }) {

    const [analyticType, setAnalyticType] = useState();
    const [ordersAggregatedTime] = useOrdersAnalytics(
        ordersDateParameters,
        'salesByTimePeriod'
    )
    useEffect(_ => {
        if (ordersDateParameters.startDate.slice(0, 10) === ordersDateParameters.stopDate.slice(0, 10)) setAnalyticType('salesByHour')
        else setAnalyticType('salesByDay')
    }, [ordersDateParameters])

    if (!ordersAggregatedTime) return <Loading />

    const salesAggregated = getSalesInfoByTimeInterval(ordersDateParameters, ordersAggregatedTime);

    const CustomTooltip = ({ active, payload }) => {
        if (!active) return null

        for (const bar of payload) {
            if (bar.payload.totalSales)
                return (
                    <Card className='pb-2 pt-2 pl-2 pr-2'>
                        <h5>
                            { analyticType === 'salesByHour' ? 
                                formatTime(bar.payload.date):
                                bar.payload.date
                            }
                        </h5>
                        { bar.payload.countSales } Order{bar.payload.countSales !== 1 && 's'}
                        <h6 className="mt-1 text-success">${(bar.payload.totalSales || 0).toFixed(2)}</h6>
                    </Card>
                )
            return null
        }
    }

    let xAxis = analyticType === 'salesByHour' ?
        <XAxis tickFormatter={formatTime} dataKey="date" angle={-60} textAnchor="end" height={80} interval={0} /> :
        <XAxis dataKey="date" angle={-45} textAnchor="end" height={80}/>

    return (
        <ResponsiveContainer height={450} className='mt-0' >
            <BarChart width="75%" height={400} data={salesAggregated} margin={{ left: 40, right: 40, top: 0 }}>
                <CartesianGrid strokeDasharray="3 3" />
                {xAxis}
                <YAxis label={{ value: 'Total Sales ($)', angle: -90, position: 'insideLeft', textAnchor: 'middle' }} domain={[0, dataMax => (Math.round(dataMax * 1.2))]} />
                <Tooltip content={<CustomTooltip />} />
                <Bar dataKey="totalSales" name="Total Sales" fill="#82ca9d" />
            </BarChart>
        </ResponsiveContainer>
    )
}
