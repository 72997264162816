import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useAuth0 } from "@auth0/auth0-react";
import Dashboard from './components/dashboard';
import 'react-toastify/dist/ReactToastify.css';
import 'react-table-6/react-table.css';
import './App.scss';


const LOCAL_URL = 'http://localhost:8081/greenShop/';
const AWS_URL = process.env.REACT_APP_API_URL || `${window.origin.split('smart-dispensary')[0]}greenshop-api.com/greenShop/`;

const API_BASE_URL = process.env.NODE_ENV === 'development' ? LOCAL_URL : AWS_URL;

toast.configure({
    autoClose: 4000,
    position: 'bottom-right'
})

function App() {

    const { isLoading, getAccessTokenSilently, loginWithRedirect, user } = useAuth0();

    const [permissions, setPermissions] = useState(null);

    const [ready, setReady] = useState(false);

    const [availableShops, setAvailableShops] = useState([]);
    const [currentShop, setCurrentShop] = useState('');

    if (isLoading) return <div>Loading...</div>;
    else if (!ready) {
        const lockJsParams = { appState: { hash: window.location.hash }, allowSignUp: false };
        getAccessTokenSilently().then(token => {
            let auth0UserInfo = JSON.parse(atob(token.split('.')[1]))

            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` };
            
            let shopIds = auth0UserInfo['http://greenstop.us/app_metadata'].shops || [auth0UserInfo['http://greenstop.us/shopId']];

            setAvailableShops(shopIds);
            let shopFromLocalStorage = localStorage.getItem('shopId');
            let selectedShop = (shopFromLocalStorage && shopIds.some(s => s === shopFromLocalStorage)) ? shopFromLocalStorage : shopIds[0];
            setCurrentShop(selectedShop);
            axios.defaults.baseURL = API_BASE_URL + selectedShop;

            axios.interceptors.response.use(undefined, error => {
                const statusCode = error.response ? error.response.status : null;
                if (statusCode === 401) loginWithRedirect(lockJsParams);
                return Promise.reject(error);
            })

            axios.interceptors.response.use(undefined, error => {
                const code = error.response.status;
                toast.dismiss();
                if(code === 400 && error.response.data) toast.error(error.response.data);
                else toast.error("Something went wrong");
                return Promise.reject(error)
            });

            setPermissions(auth0UserInfo.permissions);

            setReady(true);

        }).catch(e => loginWithRedirect(lockJsParams));
    }

    return ready && <div style={{ marginTop: '75px', }} className="App">
        <Dashboard user={user} permissions={permissions} availableShops={availableShops} currentShop={currentShop}/>
    </div>
}

export default App;
