import React from 'react';
import Card from 'react-bootstrap/Card';
import useOrdersAnalytics from '../../hooks/useOrdersAnalytics';

export default function SalesSummary({ ordersDateParameters }) {

    const [results] = useOrdersAnalytics(ordersDateParameters, 'salesSummary');
    const salesSummary = (results) ? results[0] : undefined;
    const salesExist = Boolean(salesSummary)

    if (!salesExist) {
        return <p/>
    } else {
        return (<>
                <Card className="mb-3 mt-1">
                    <h3 style={{ color: '#26A65B'}}>Total Sales</h3>
                    <h4>${salesSummary.totalSales.toFixed(2)}</h4>
                </Card>
                <Card className="mb-3">
                    <h3 style={{ color: '#26A65B', fontSize: '30px'}}>Average Sale</h3>
                    <h4>${salesSummary.averageSale.toFixed(2)}</h4>
                </Card>
                <Card className="mb-3">
                    <h3 style={{ color: '#26A65B'}}># Orders</h3>
                    <h4>{salesSummary.countSales}</h4>
                </Card>
                <Card className="mb-3">
                    <h3 style={{ color: '#26A65B'}}># Products</h3>
                    <h4>{salesSummary.countProducts}</h4>
                </Card>
        </>)
    }
}
