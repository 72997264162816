import { useState, useEffect } from 'react';
import axios from 'axios';

const LOCATIONS_URL = '/backstock';

export default function useBackstockLocations() {
    const [locations, setLocations] = useState([]);

    const [reload, setReload] = useState(false);

    useEffect(() => {
        axios.get(LOCATIONS_URL).then(({data}) => {
            console.log('useLocations: ', data)
            setLocations(data);
        }).catch(console.error)
    }, [reload])

    return [locations, () => setReload(!reload)];
}
