const ENV_SLUG = window.origin.split('.')[0].split('//')[1];

let auth0Config;
if (ENV_SLUG === 'smart-dispensary' || ENV_SLUG === 'prod' || ENV_SLUG === 'beta') {
    auth0Config = {
        domain: "auth.smart-dispensary.com",
        clientId: "8qN5T4VgvS3Quq5k3oHL9YLSkJW22wnY",
        audience: 'https://prod.smart-dispensary.com/api'
    };
} else if (ENV_SLUG === 'stage') {
    auth0Config = {
        domain: "greenstop-stage.us.auth0.com",
        clientId: "HTkB4FcYn23wjLZVXfLHQSV5kzEIENkr",
        audience: 'https://stage.smart-dispensary.com/api'
    };
} else {
    auth0Config = {
        domain: "greenstop-dev.auth0.com",
        clientId: "NymJ8ilYbov73gg5Xgsh0O4cUBbWsEDB",
        audience: "http://localhost:8081"
    };
}

export default auth0Config;
