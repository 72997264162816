import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import InventoryTable from './inventoryTable';
import EditProductModal from './modals/editProductModal';
import AssignPackageModal from './modals/assignPackageModal';
import Loading from './loading';
import styled from 'styled-components';
import useCategoryMap from '../hooks/useCategoryMap';

const ALL_INVENTORY_URL = '/inventory/all';
const KIOSK_INVENTORY_URL = '/inventory/kiosk';
const BACKSTOCK_URL = '/inventory/backstock';


const INVENTORY_URL_MAP = {
    totalInventory: ALL_INVENTORY_URL,
    kioskInventory: KIOSK_INVENTORY_URL,
    backStock: BACKSTOCK_URL
}

const InventoryContainer = styled.div`
    .btn {
        float: right;
        padding: 10px;
        background: #FFFFFF;
        border: 1px solid #26A65B;
        color: #26A65B;
        margin-top: 7px;
        margin-right: 7px;
    }
    .btn:hover {
        background: #26A65B;
        color: #FFFFFF;
    }
    @media(max-width: 500px) {
        .btn {
            float: none;
        }

    }
`;

export default function Inventory(props) {

    const [inventoryItems, setInventoryItems] = useState();
    const [categoryMap] = useCategoryMap();
    const [selectedTab, setSelectedTab] = useState('totalInventory');
    const [editingProduct, setEditingProduct] = useState(null);
    const [newInventoryItem, setNewInventoryItem] = useState(null);
    const [reload, setReload] = useState(false);

    let permissions = props.permissions;

    useEffect(() => {
        let inventoryUrl = INVENTORY_URL_MAP[selectedTab];
        setInventoryItems(null);
        setEditingProduct(null);
        axios.get(inventoryUrl).then(resp => setInventoryItems(resp.data)).catch(err => {
            console.error(err);
            // toast.error('Inventory Database not responding');
        });
    }, [selectedTab, reload]);

    if (!inventoryItems) return <Loading />;
    
    inventoryItems.forEach(item => item.categoryName = categoryMap[item.category] && categoryMap[item.category].label)

    return (
        <>
        { permissions && (permissions.some(per => per === 'inventory') || permissions.some(per => per === 'admin')) ?
            <div>
                <div>
                    {
                        props.shop && props.shop.inventoryManagement === 'greenstop' && <>
                            {editingProduct ? <EditProductModal productIn={editingProduct} hide={() => setEditingProduct(null)} afterSave={_ => setReload(!reload)} /> : null}
                            {newInventoryItem ? <AssignPackageModal packageToAssign={newInventoryItem} hide={() => setNewInventoryItem(null)} afterSave={_ => setReload(!reload)} /> : null}
                            <InventoryContainer>
                                <Button onClick={() => setNewInventoryItem({ Label: 'none' })}>Add Non-METRC item to inventory</Button>
                            </InventoryContainer>
                        </>
                    }
                    <Nav variant="tabs" defaultActiveKey='totalInventory' className="pt-3" onSelect={tabKey => setSelectedTab(tabKey)}>
                        <Nav.Item>
                            <Nav.Link eventKey='totalInventory'> Total Inventory </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey='kioskInventory'> Kiosk Inventory </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="backStock"> Back Stock </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <InventoryTable inventoryItems={inventoryItems} setEditingProduct={p => setEditingProduct({ ...p, _id: p.productId })} />
                </div>
            </div>
            :
            <h1 style={{marginTop: '30px',}}>You do not have access to the inventory</h1>
        }
        </>
    )
}
