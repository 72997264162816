import React, { useState, useMemo } from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Col from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import getOrdersDateParams from '../common/getOrdersDateParams';
import useOrders from '../hooks/useOrders';
import styled from 'styled-components';
import ViewSales from './sales/viewSales';
import Loading from './loading';
import CustomPieChart from './sales/salesByCustomPieChart';
import SalesByTimeIntervalChart from './sales/salesByTimeIntervalChart';
import SalesSummary from './sales/salesSummary';
import TopFiveProducts from './sales/topFiveProductsChart';
import useCategoryMap from '../hooks/useCategoryMap';
import { DateTime } from 'luxon';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NoSales from './sales/noSales';
import Tips from './tips';
import papa from 'papaparse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faDownload } from '@fortawesome/free-solid-svg-icons';
import useBlazeEmployeeNameMap from '../hooks/useBlazeEmployeeNameMap';

function download(filename, text) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

const generateCSV = async (orders, dateParams, blazeEmployeeNameMap) => {
    let total = 0;
    let subTotal = 0;
    let totalDiscount = 0;
    let totalTax = 0;
    let totalTips = 0;
    let totalAmountPaidByType = {
        cash: 0,
        credit: 0,
        debit: 0,
        gstop: 0,
        cashlessATM: 0
    };
    let totalChange = 0;
    let totalUndispensed = 0;
    let totalFees = 0;

    let breakdown = orders.filter(o => o.paymentType !== 'gstop').map(order => {
        total += +order.total;
        subTotal += +order.subTotal;
        totalDiscount += +order.discount || 0;
        totalTax += +order.tax || 0;
        totalTips += +order.tipAmount || 0;
        totalFees += +order.cardFee || +order.creditResponse?.fee || 0;
        totalAmountPaidByType[order.paymentType] += +order.amountPaid || 0;
        totalChange += +order.change;
        let undispensedChange =  (order.change || 0) - (order.changeDispensed || 0);
        totalUndispensed += undispensedChange;
        let employeeName = order.blazeTransaction ? blazeEmployeeNameMap[order.blazeTransaction.assignedEmployeeId] : order.employeeName;
        return {
            "Transaction #": `#${order.transactionNumber}`,
            "Terminal ID": order.terminalId,
            "Sub Total": `$${order.subTotal.toFixed(2)}`,
            "Discount": `$${(order.discount || 0).toFixed(2)}`,
            "Tax": `$${(order.tax || 0).toFixed(2)}`,
            "Total": `$${order.total.toFixed(2)}`,
            "Tip": `$${(+order.tipAmount || 0).toFixed(2)}`,
            "Card Fee": `$${(order.cardFee || +order.creditResponse?.fee|| 0).toFixed(2)}`,
            ...Object.keys(totalAmountPaidByType).filter(k => k !== order.paymentType).reduce((out, key) => ({...out, [`Paid (${key})`]: ""}), {}),
            [`Paid (${order.paymentType})`]: `$${(+order.amountPaid || 0).toFixed(2)}`,
            "Change": `$${(order.change || 0).toFixed(2)}`,
            "Undispensed Change": `$${(undispensedChange).toFixed(2)}`,
            "Date": DateTime.fromJSDate(new Date(order.timestamp)).toFormat('f'),
            "Customer ID": order.customerId,
            "Customer Name": order.customer?.name,
            "Employee Name": employeeName,
            "Order Id": order._id,
            "Products": order.items.map(p => `${p.name} (${p.quantity} X $${p.price})`)
        }
    })

    let paymentSums = Object.keys(totalAmountPaidByType).filter(k => totalAmountPaidByType[k]).reduce((out, k) => ({...out, [`Paid (${k})`]: `$${(totalAmountPaidByType[k] || 0).toFixed(2)}`}), {})

    Object.keys(totalAmountPaidByType).filter(k => !totalAmountPaidByType[k]).forEach(k => delete breakdown[0][`Paid (${k})`]);
    breakdown[0] = {...breakdown[0]}
    breakdown.push({})
    breakdown.push({
        "Transaction #": 'Totals:',
        "Sub Total": `$${subTotal.toFixed(2)}`,
        "Discount": `$${(totalDiscount).toFixed(2)}`,
        "Tax": `$${(totalTax || 0).toFixed(2)}`,
        "Total": `$${total.toFixed(2)}`,
        "Tip": `$${(totalTips || 0).toFixed(2)}`,
        "Card Fee": `$${(totalFees || 0).toFixed(2)}`,
        ...paymentSums,
        "Change": `$${totalChange.toFixed(2)}`,
        "Undispensed Change": `$${(totalUndispensed).toFixed(2)}`
    })

    let csvStr = papa.unparse(breakdown);
    download(`${orders[0]?.shopId} orders - ${getPageHeader(dateParams)}.csv`, csvStr);
}

const isSameWeek = (d1, d2) => DateTime.fromJSDate(d1).weekNumber === DateTime.fromJSDate(d2).weekNumber;

const CustomDatePicker = ({setOrdersDateParameters, periodType, startDateIn, stopDateIn, close}) => {
    const [startDate, setStartDate] = useState(new Date(startDateIn));
    const [stopDate, setStopDate] = useState(new Date(stopDateIn));

    const onChange = dates => {
        if (dates instanceof Date) dates = [DateTime.fromJSDate(dates).startOf(periodType).toJSDate(), DateTime.fromJSDate(dates).endOf(periodType).toJSDate()]

        const [start, end] = dates;

        setStartDate(start);
        setStopDate(end);

        if (end) {
            setOrdersDateParameters(getOrdersDateParams({ startDate: start, stopDate: end }));
            close();
        }
    };

    return ( <div className={periodType === 'week' ? "weekpicker" : ""}>
        <DatePicker
            selected={startDate}
            onChange={onChange}
            calendarStartDay={1}
            dayClassName={day => periodType === 'week' && isSameWeek(day, startDate) ? "react-datepicker__day--selected" : ""}
            startDate={periodType === 'custom' && startDate}
            endDate={periodType === 'custom' && stopDate}
            showMonthYearPicker={periodType === 'month'}
            selectsRange={periodType === 'custom'}
            inline
        />
    </div>);
};

export default function AnalyzeSales() {
    const [ordersDateParameters, setOrdersDateParameters] = useState(getOrdersDateParams);
    const [periodType, _setPeriodType] = useState('day');
    const [matchingOrders] = useOrders(ordersDateParameters);
    const [categoryMap] = useCategoryMap();
    const [tabKey, setTabKey] = useState('byDate');
    const [showDatePicker, setShowDatePicker] = useState(false);

    const [blazeEmployeeNameMap] = useBlazeEmployeeNameMap()

    const setPeriodType = periodType => {
        if (periodType !== 'custom') setOrdersDateParameters(getOrdersDateParams(periodType));
        _setPeriodType(periodType);
    }
    const ControlledTabs = useMemo( _ =>
        <Container className="mt-4">
            <Tabs
                id="controlled-tab-example"
                activeKey={tabKey}
                onSelect={(k) => setTabKey(k)}
                className="mb-4"
            >
                <Tab eventKey="byDate" title="Trends">
                    { tabKey === 'byDate' && <SalesByTimeIntervalChart ordersDateParameters={ordersDateParameters} />}
                </Tab>
                <Tab eventKey="topProducts" title="Top Products">
                    { tabKey === 'topProducts' && <TopFiveProducts ordersDateParameters={ordersDateParameters} />}
                </Tab>
                <Tab eventKey="byCannabisType" title="Cannabis Types">
                    { tabKey === 'byCannabisType' && <CustomPieChart ordersDateParameters={ordersDateParameters} analytic={"cannabisType"} />}
                </Tab>
                <Tab eventKey="byProductType" title="Product Types">
                    { tabKey === 'byProductType' && <CustomPieChart ordersDateParameters={ordersDateParameters} analytic={"productCategory"} metadataMap={categoryMap} />}
                </Tab>
                <Tab eventKey="paymentMethod" title="Payment Methods">
                    { tabKey === 'paymentMethod' && <CustomPieChart ordersDateParameters={ordersDateParameters} analytic={"cashCreditSummary"} />}
                </Tab>
                <Tab eventKey="tips" title="Tips">
                    { tabKey === 'tips' && <Tips ordersDateParameters={ordersDateParameters} />}
                </Tab>
                <Tab eventKey="transLog" title="Transaction Log">
                    { tabKey === 'transLog' && <ViewSales ordersDateParameters={ordersDateParameters} />}
                </Tab>
            </Tabs>
        </Container>
    , [tabKey, ordersDateParameters, categoryMap])

    if (!matchingOrders || !categoryMap ) return <Loading />;

    const popover = (
        <Popover id="popover-basic">
            <Popover.Title as="h3">Select Custom Date Range</Popover.Title>
            <Popover.Content>
                <CustomDatePicker setOrdersDateParameters={setOrdersDateParameters} periodType={periodType} startDateIn={ordersDateParameters?.startDate} stopDateIn={ordersDateParameters?.startDate} close={_ => setShowDatePicker(false)}/>
            </Popover.Content>
        </Popover>
    );

    const CustomDatePopover = () => (
        <OverlayTrigger trigger="click" placement="left" overlay={popover} rootClose onToggle={setShowDatePicker} show= {showDatePicker}>
            <Button>{getPageHeader(ordersDateParameters)}</Button>
        </OverlayTrigger>
    );


    return (
        <TopContainer style={{ marginBottom: '75px' }}>
            <Container>
                <Row className='mt-0 pt-2 pb-2' style={{ backgroundColor: "#f7f7f7" }} >
                    <Col className="subheader pt-0">
                        <h1 className="subheader">
                            Sales Summary
                        <Button variant='outline-primary border-0' onClick={_ => generateCSV(matchingOrders?.results, ordersDateParameters, blazeEmployeeNameMap)}><FontAwesomeIcon icon={faDownload}/></Button>
                        </h1>
                    </Col>
                    <Col className="pt-0">
                        <ButtonGroup toggle className='pt-2 mr-2'>
                            <Button onClick={_ => setOrdersDateParameters({
                                startDate: DateTime.fromISO(ordersDateParameters.startDate).minus( { days: 1 }).startOf(periodType).toISO(),
                                stopDate: DateTime.fromISO(ordersDateParameters.startDate).minus( { days: 1 }).endOf(periodType).toISO()
                            })} disabled={periodType === 'custom'} className='bg-success'><FontAwesomeIcon icon={faChevronLeft}/></Button>
                            <CustomDatePopover />
                            <Button onClick={_ => setOrdersDateParameters({
                                startDate: DateTime.fromISO(ordersDateParameters.stopDate).plus( { days: 1 }).startOf(periodType).toISO(),
                                stopDate: DateTime.fromISO(ordersDateParameters.stopDate).plus( { days: 1 }).endOf(periodType).toISO()
                            })} disabled={periodType === 'custom'} className='bg-success'><FontAwesomeIcon icon={faChevronRight}/></Button>
                        </ButtonGroup>
                    </Col>
                    <Col className="pt-0">
                        <ButtonGroup toggle className='pt-2 mr-0'>
                            <Button className={periodType === 'day' ? 'bg-success' : ''} onClick={() => setPeriodType('day')}>Day</Button>
                            <Button className={periodType === 'week' ? 'bg-success' : ''} onClick={() => setPeriodType('week')}>Week</Button>
                            <Button className={periodType === 'month' ? 'bg-success' : ''} onClick={() => setPeriodType('month')}>Month</Button>
                            <Button className={periodType === 'custom' ? 'bg-success' : ''} onClick={() => {
                                setPeriodType('custom');
                                setShowDatePicker(true);
                            }}>Custom</Button>
                        </ButtonGroup>
                    </Col>
                </Row>
                <SalesSummary ordersDateParameters={ordersDateParameters} />
                { (matchingOrders.results.length < 1) ?
                    <NoSales message={"No Sales Data for Selected Time Period"} /> :
                    ControlledTabs
                }
            </Container>
        </TopContainer>
    )
}


function getPageHeader(ordersDateParameters) {
    if (DateTime.fromISO(ordersDateParameters.startDate).toISODate() === DateTime.fromISO(ordersDateParameters.stopDate).toISODate()) {
        return DateTime.fromISO(ordersDateParameters.startDate).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
    }
    else {
        return DateTime.fromISO(ordersDateParameters.startDate).toLocaleString(DateTime.DATE_MED) + ' - ' + DateTime.fromISO(ordersDateParameters.stopDate).toLocaleString(DateTime.DATE_MED)
    }
}


const TopContainer = styled.div`
    .card {
        h1 {
            font-size: 32px;
        }
    }
    .products-sold {
        row {
            h1 {
                color: "#26A65B";
            }
        }
        ul {
            list-style: none;
            padding-left: 0;
            li {
                justify-content: space-between;
                display: flex;
                span {
                    width: 50%;
                    text-align: center;
                    margin-bottom: 5px;
                }
            }
        }
    }
    .subheader {
        font-size: 40px;
        text-align: left;
        vertical-align: middle;
    }
`;
