import React, { useEffect, useState } from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from 'react-bootstrap/Container';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { useTable } from 'react-table';
import getOrdersDateParams from '../common/getOrdersDateParams';
import Loading from './loading';
import axios from 'axios';
import { DateTime } from 'luxon';
import DatePicker from "react-datepicker";
import NoSales from './sales/noSales';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";


const totalItems = p => p.items?.reduce((total, current) => total + (current.quantity), 0);

export default function OrderLog(props) {
  const date = props.location.search.split('=').pop()
  const [ordersDateParameters, setOrdersDateParameters] = useState((date) ? { startDate: DateTime.fromISO(date).toISO(), stopDate : DateTime.fromISO(date).plus({days: 1}).toISO() } : getOrdersDateParams);
  const [queryParameters, setQueryParameters] = useState();
  const [orders, setOrders] = useState()
  
  useEffect(() => {
    setQueryParameters({
      ...ordersDateParameters,
      sortBy: { 'timestamp': -1 }
    })
  }, [ordersDateParameters])

  useEffect(() => {
    (async () => {
      if (queryParameters) {
        setOrders()
        const result = await axios.post('/orders', queryParameters);
        setOrders(result.data.results);
      }
    })();
  }, [queryParameters]);

  const CustomDatePicker = () => {
    const startDate = new Date();

    const onChange = date => {
      setOrdersDateParameters(getOrdersDateParams({ startDate: date, stopDate: date }))
    };

    return (
      <DatePicker
        selected={startDate}
        onChange={onChange}
        closeOnScroll={true}
        inline
      />
    );
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Select Custom Date Range</Popover.Title>
      <Popover.Content>
        <CustomDatePicker />
      </Popover.Content>
    </Popover>
  );

  const CustomDatePopover = () => (
    <ButtonGroup className='ml-auto'>
      <Button onClick={() => handleDateArrow(-1)}><FontAwesomeIcon icon={faAngleDoubleLeft}/></Button>
      <OverlayTrigger trigger="click" placement="left" overlay={popover} rootClose>
        <Button>{DateTime.fromISO(ordersDateParameters.startDate).toLocaleString(DateTime.DATE_FULL)}</Button>
      </OverlayTrigger>
      <Button onClick={() => handleDateArrow(1)}><FontAwesomeIcon icon={faAngleDoubleRight}/></Button>
    </ButtonGroup>
  );

  const handleDateArrow = (offsetDate) => {
    let startDate = new Date(ordersDateParameters.startDate);
    let stopDate = new Date(ordersDateParameters.stopDate);

    let newStartDate = startDate.setDate(startDate.getDate() + offsetDate);
    let newStopDate = stopDate.setDate(stopDate.getDate() + offsetDate);

    newStartDate = new Date(newStartDate).toISOString();
    newStopDate = new Date(newStopDate).toISOString();

    setOrdersDateParameters({ startDate: newStartDate, stopDate: newStopDate })
  }

  const columns = React.useMemo(
    () => [
      {
        id: 'preorder',
        Cell: p => p?.row?.original?.preorder ? <span className='text-primary font-italic' style={{marginRight: '-50%'}}>preorder</span> : null,
        props: {className: 'mx-0'}
      },
      {
        Header: 'Order Details',
        id: 'orderDetails',
        Cell: p => <Button href={`#/orders/${p.row.original._id}`}>Order Details</Button>,
      },
      {
        Header: 'Status',
        id: 'status',
        accessor: p => {
          if (p.done) {
            if ((p.vendResults?.length || 0) >= totalItems(p)) return <span className="text-success">Vended</span>
            else if (p.vendResults?.length) return <span className="text-info">Vending</span>
            else return <span className="text-success">Paid</span>
          }
          if (p.canceled) return <span className="text-danger">Canceled</span>
          return <span className="text-warning">In Progress</span>
        }
      },
      {
        Header: 'Time',
        accessor: p => DateTime.fromISO(p.timestamp).toLocaleString(DateTime.TIME_SIMPLE),
      },
      {
        Header: 'Terminal',
        id: 'terminalId',
        accessor: p => p.sidesVended ? `Side ${Object.keys(p.sidesVended).map(tId => tId.split('-t')[1]).sort().join(', ')}` : (`Side ${p.terminalId?.split('-t')[1]}` || 'unknown')
      },
      {
        Header: 'Customer',
        id: 'customerName',
        accessor: p => p.customer && p.customer.name
      },
      {
        Header: 'Products Vended',
        id: 'productsVended',
        accessor: p => `${p.vendResults?.length || 0}/${totalItems(p)}`
      },
      {
        Header: 'Total',
        id: 'total',
        accessor: p => p.total.toFixed(2)
      }],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows
  } = useTable({ columns, data: orders || []})

  return (
    <Container>
      <Breadcrumb className="mt-3">
        <Breadcrumb.Item active>Order Log</Breadcrumb.Item>
        <CustomDatePopover />
      </Breadcrumb>
      {
        !orders ? <Loading/> :
          (orders.length === 0) ? <NoSales message={"No Orders for Selected Date"} /> :
            <Table {...getTableProps()} style={{ margin: 'auto' }}>
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr className='rt-tr' {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th className='rt-thead'
                        {...column.getHeaderProps()}
                      >
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                  prepareRow(row)
                  return (
                    <tr className='rt-tr' {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        return (
                          <td
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </Table>
      }
    </Container>
  )
}

