import { useState, useEffect } from 'react';
import axios from 'axios';

const SHOP_URL = '/settings';

export default function useShop() {
    const [shop, setShop] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const refreshShop = _ => setRefresh(!refresh);

    useEffect(() => {
        axios.get(SHOP_URL)
            .then(({ data }) => setShop(data || {}))
            .catch(e => setShop({}));
    }, [refresh])

    return [shop, refreshShop];
}
