import React, { useCallback, useState } from "react";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import useOrdersAnalytics from "../../hooks/useOrdersAnalytics";
import Loading from "../loading";

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF9B85', '#FA70FF'];

const renderActiveShape = (props) => {
    const {
        cx,
        cy,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        fill,
        payload,
        percent,
        value
    } = props;

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill} fontSize='30px' >
                {payload.name}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <text fontSize='25px'
                x={'50%'}
                y={outerRadius*2.1 + 60}
                textAnchor='middle'
                fill={fill}
            >{`$${value.toFixed(2)} (${(percent * 100).toFixed(2)}%)`}</text>
            <text fontSize='25px'
                x={'50%'}
                y={outerRadius*2.1 + 60}
                dy={30}
                textAnchor='middle'
                fill="#999"
            >
                {(payload.count && payload.count === 1) ? 
                    `${payload.count} unit` : 
                (payload.count) ?
                    `${payload.count} units` : 
                    ``}
            </text>
        </g>
    );
};

export default function CustomPieChart({ ordersDateParameters, analytic , metadataMap={}, }) {

    let [data] = useOrdersAnalytics(ordersDateParameters, analytic)

    const [activeIndex, setActiveIndex] = useState(0);
    const onPieEnter = useCallback(
        (_, index) => {
            setActiveIndex(index);
        },
        [setActiveIndex]
    );

    if (!data) return <Loading />;

    if (Object.keys(metadataMap).length > 0) {
        data = data.map((current) => {
            let name = metadataMap[current.name] && metadataMap[current.name].label ? metadataMap[current.name].label : current.name;

            return { name, value: current.value, count: current.count };
    })};

    return (
        <ResponsiveContainer aspect={.8}>
            <PieChart margin={{left: 20, right: 20, top: 0, bottom: 0}}>
                <Pie
                    activeIndex={activeIndex}
                    activeShape={renderActiveShape}
                    data={data}
                    innerRadius={'50%'}
                    outerRadius={'60%'}
                    cy={'25%'}
                    fill="#82ca9d"
                    dataKey="value"
                    onMouseEnter={onPieEnter}>
                    {data.map((entry, index) => (
                        <Cell key={index} fill={COLORS[index]} />
                    ))
                    }
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );
}
