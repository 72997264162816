import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/esm/Row';
import useOrdersAnalytics from '../../hooks/useOrdersAnalytics';

export default function SalesSummary({ ordersDateParameters }) {

    const [results] = useOrdersAnalytics(ordersDateParameters, 'salesSummary');
    const salesSummary = (results) ? results[0] : undefined;
    const salesExist = Boolean(salesSummary)

    return (
        <Row className="mt-0 ml-1 mr-1 pt-2" >
            <Col lg={3} className="pt-2" >
                <Card>
                    <h1 style={{ color: '#26A65B', }}>Total Sales: </h1>
                    <h2>${(salesExist) ? salesSummary.totalSales.toFixed(2) : "0.00"}</h2>
                </Card>
            </Col>
            <Col lg={3} className="pt-2" >
                <Card>
                    <h1 style={{ color: '#26A65B', }}>Average Sale: </h1>
                    <h2>${(salesExist) ? salesSummary.averageSale.toFixed(2) : "0.00"}</h2>
                </Card>
            </Col>
            <Col lg={3} className="pt-2">
                <Card>
                    <h1 style={{ color: '#26A65B', }}>Total Orders: </h1>
                    <h2>{(salesExist) ? salesSummary.countSales : 0}</h2>
                </Card>
            </Col>
            <Col lg={3} className="pt-2">
                <Card>
                    <h1 style={{ color: '#26A65B', }}>Total Products: </h1>
                    <h2>{(salesExist) ? salesSummary.countProducts : 0}</h2>
                </Card>
            </Col>
        </Row>)
}

