import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import axios from 'axios';
import EditEmployeeModal from '../modals/editEmployeeModal';
import DeleteEmployeeModal from '../modals/deleteEmployeeModal';

const EMPLOYEE_URL = '/employees';

export default function EmloyeeSettings({ currentShopSettings }) {

    const [employeeList, setEmployeeList] = useState([]);
    const [editingEmployee, setEditingEmployee] = useState(null);
    const [employeeToDelete, setEmployeeToDelete] = useState(null);

    useEffect(() => {
        axios.get(EMPLOYEE_URL).then(({ data }) => {
            setEmployeeList(data);
        }).catch(console.error)

    }, []);

    const reloadEmployees = () => {
        setEditingEmployee(null);
        axios.get(EMPLOYEE_URL).then(({ data }) => {
            setEmployeeList(data);
        }).catch(console.error)
    }

    const deleteEmployee = employeeToDelete => {
        axios.delete(EMPLOYEE_URL + '/' + employeeToDelete.user_id).then(reloadEmployees).then(setEmployeeToDelete(null)).catch(console.error);
    };


    return ( <>
                <Container style={{ marginBottom: 40 }} className='mt-3'>
                    {employeeList.length > 0 ?
                        <>
                            <EmployeeHeader>
                                <h1>Current Employees</h1>
                                <Button onClick={() => setEditingEmployee({ user_id: null, name: '', email: '', })}>Add Employee</Button>
                            </EmployeeHeader>
                            {employeeList.map((emp, idx) => {
                                return (
                                    <EmployeeContainer key={idx}>
                                        <div className="employee-info">
                                            <img alt="employee" src={emp.picture} />
                                        </div>
                                        <div className="employee-data">
                                            <h2>{emp.name}</h2>
                                            <h2>{emp.email}</h2>
                                        </div>
                                        <div className="employee-actions">
                                            <Button onClick={() => setEditingEmployee(emp)}>Edit</Button>
                                            <Button className="text-danger" variant="outline-danger" onClick={() => setEmployeeToDelete(emp)}>Delete</Button>
                                        </div>
                                    </EmployeeContainer>
                                )
                            }
                            )}
                        </>
                        :
                        null
                    }
                </Container>
                <div>
                    {
                        editingEmployee ?
                            <EditEmployeeModal employeeIn={editingEmployee}
                                shop={currentShopSettings}
                                hide={() => setEditingEmployee(null)}
                                afterSave={reloadEmployees} /> : null
                    }
                    {
                        employeeToDelete ?
                            <DeleteEmployeeModal name={employeeToDelete.name}
                                yes={() => deleteEmployee(employeeToDelete)}
                                no={() => setEmployeeToDelete(null)} /> : null
                    }
                </div>
        </>
    )
}

const EmployeeHeader = styled.div`
    display: flex;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 45px;
    h1 {
        margin-bottom: 0 !important;
        vertical-align: middle;
        width: 80%;
    }
    button {
        margin-top: 0px !important;
    }
    @media(max-width: 800px) {
        display: block;
        h1 {
            text-align: center;
            width: 100%;
            padding-bottom: 20px;
        }
    }
    @media(max-width: 500px) {
        display: block;
        h1 {
            text-align: center;
            width: 100%;
            padding-bottom: 20px;
        }
    }
`;

const Container = styled.div`
    width: 75%;
    justify-content: center;
    margin: 0 auto;
    padding-bottom: 30px;
    h1 {
        margin-bottom: 45px;
    }
    button {
        margin-top: 45px;
    }
    .form-group {
        .input-group {
            width: 60%;
            display: inline-block;
        }
        .input-group-append {
            width: auto;
            display: inline-block;
        }
    }
    .form-group label{
        width: 20% !important;
        display: inline-block;
        text-align: left;
        font-weight: bold;
    }
    .form-group input{
        width: 60%;
        display: inline-block;
    }
    .form-group select{
        width: 60%;
        display: inline-block;
    }
    .form-row {
        width: 80%;
        margin: 0 auto;
        justify-content: space-between;
        .col {
            width: auto;
            flex-basis: auto;
            flex-grow: 0;
            text-align: left;
            padding-left: 0;
            padding-right: 0;
            input {
                width: 80%;
            }
        }
        .state {
            select {
                width: 80%;
                display: inline-block;
            }
            width: 40%;
        }
    }
    .form-check {
        color: #000000;
        margin-bottom: 15px;
        margin-right: 15px;
        input {
            position: relative;
            width: auto;
        }
        label {
            width: auto !important;
        }
    }
    .blazeTerminal {
        width: 80%;
        margin: 0 auto;
        .form-group label{
            width: 40% !important;
            display: inline-block;
            text-align: left;
            font-weight: bold;
        }
        .form-group input{
            width: 40%;
            display: inline-block;
        }
    }
    .treezConfig {
        width: 80%;
        margin: 0 auto;
        .form-group label{
            width: 40% !important;
            display: inline-block;
            text-align: left;
            font-weight: bold;
        }
        .form-group input{
            width: 40%;
            display: inline-block;
        }
    }
    .employeeForm {
        h2 {
            font-size: 18px;
            text-align: left;
            margin-left: 10%;
        }
        .permissions {
            width: 80%;
            margin: 0 auto;
            margin-top: 15px;
            text-align: left;
        }
    }
`;

const EmployeeContainer = styled.div`
    text-align: left;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 30px;
    h2 {
        display: inline-block;
        font-size: 24px;
    }
    .employee-info {
        width: 50px;
        display: inline-block;
        justify-content: space-between;
        img {
            width: 50px;
            border-radius: 50%;
            vertical-align: middle;
        }
        h2 {
            width: auto;
            margin-left: 25px;
        }
    }
    .employee-data {
        display: inline-block;
        justify-content: space-between;
        vertical-align: middle;
        width: 70%;
        h2 {
            width: auto;
            margin-left: 25px;
        }

    }
    .employee-actions {
        display: inline-block;
        margin-top: 20px;
        button {
            margin-top: 0px;
        }
        .text-danger {
            margin-left: 10px;
        }

    }
    @media(max-width: 800px and min-width: 501px) {
        display: block;
        width: 40%;
        margin: 0 auto;
        margin-bottom: 20px;
        .employee-info {
            text-align: left;
            display: inline-block;
            justify-content: space-between;
            text-align: center;
            img {
                margin: 0 auto;
                margin-top: 0px;
                margin-right: 45px;
            }
            h2 {
                margin-left: 0;
                width: 100%;
            }
        }
        .employee-actions {
            display: block;
            justify-content: space-between;
        }
    }
    @media(max-width: 500px) {
        display: block;
        .employee-info {
            text-align: center;
            width: 100%;
            img {
                margin-top: 20px;
            }
            h2 {
                margin-left: 0;
            }
        }
        .employee-data {
            h2 {
                margin-left: 0;
            }

        }
        .employee-actions {
            display: flex;
            justify-content: space-between;
        }
    }
`;
