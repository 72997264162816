import React, {useState, useEffect} from 'react';
import styled, { keyframes } from 'styled-components';
import StopSign from '../stopsign-logo.png';

export default function Loading() {

    const [showSpinner, setShowSpinner] = useState(false)

    useEffect(_ => {
        let timeoutId = setTimeout(_ => setShowSpinner(true), 3000)
        return _ => clearTimeout(timeoutId);
    })

    return showSpinner ?
        <SpinnerContainer>
            <img alt="loading-spin" src={StopSign} animation="border" role="status" />
            <p className="loading">Loading</p>
        </SpinnerContainer> : null
};

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
`;

const dots = keyframes` 
    0%, 20% {
        color: rgb(38,166,91);
        text-shadow:
        .25em 0 0 rgba(0,0,0,0),
        .5em 0 0 rgba(0,0,0,0);
    }
    40% {
        color: #26A65B;
        text-shadow:
        .25em 0 0 rgba(0,0,0,0),
        .5em 0 0 rgba(0,0,0,0);
    }
    60% {
        text-shadow:
        .25em 0 0 #26A65B,
        .5em 0 0 rgba(0,0,0,0);
    }
    80%, 100% {
        text-shadow:
        .25em 0 0 #26A65B,
        .5em 0 0 #26A65B;
    }
`;

const SpinnerContainer = styled.div`
	position: absolute;
	margin-top: 20%;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	z-index: 4;
	img {
		width: 200px;
		height: 200px;
		animation: ${rotate} 2s infinite linear;
	}
	p {
        color: #26A65B;
        font-size: 1.5em;
        margin-top: 20px;
        font-weight: bold;
        text-align: center;
	}
	.loading:after {
        content: ' .';
        animation: ${dots} 1s steps(5, end) infinite;
	}
`;
