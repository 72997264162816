import { useState, useEffect } from 'react';
import axios from 'axios';

const EMPLOYEE_NAME_MAP_URL = '/blaze/employeeNameMap';

export default function useBlazeEmployeeNameMap() {
    const [employeeNameMap, setEmployeeNameMap] = useState({});

    useEffect(() => {
        axios.get(EMPLOYEE_NAME_MAP_URL).then(({data}) => {
            setEmployeeNameMap(data || {});
        }).catch(e => setEmployeeNameMap({}))
    }, [])

    return [employeeNameMap];
}
