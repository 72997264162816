import React, { useState, useCallback } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import { HashRouter as Router, Route, Link } from 'react-router-dom';
import Inventory from './inventory';
import Packages from './packages';
import AnalyzeSales from './analyzeSales';
import Home from './home';
import Support from './support';
import CustomerLog from './customerLog';
import OrderLog from './orderLog';
import Settings from './settings/settings';
import Invoices from './invoices';
import KioskManagement from './kioskManagement';
import Products from './products';
import OpenOrders from './openOrders';
import HandleOrder from './handleOrder';
import EditCategoryModal from './modals/editCategoryModal';
import EditProductModal from './modals/editProductModal';
import EditBackstockLocationModal from './modals/editBackstockLocationModal';
import useShop from '../hooks/useShop';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import StopSign from '../stopsign-logo.png';
import TerminalDetails from './terminalDetails';
import HISTORY from '../common/history';
import CustomerDetails from './customerDetails';
import OrderDetails from './orderDetails';
import Preorders from './preorders';
import PreorderDetails from './preorderDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUser } from '@fortawesome/free-solid-svg-icons';

export default function Dashboard({user, permissions, currentShop, availableShops}) {

    const navStart = localStorage.getItem('nav_open');

    const { logout } = useAuth0();
    const [view, setView] = useState(navStart === "closed" ? 'closed' : 'open');
    const [createMenuOpen, setCreateMenuOpen] = useState(false);
    const [shop] = useShop();

    const setShopId = useCallback(shopId => {
        localStorage.setItem('shopId', shopId);
        window.location.reload(false);
    }, [])

    let expanded = '';

    if (view === "open") {
        localStorage.setItem('nav_open', 'open');
        expanded = "unexpanded";
    } else {
        localStorage.setItem('nav_open', 'closed');
        expanded = "expanded";
    }

    const hasPermission = permissionsArr => permissions && (permissions.some(userPermission => permissionsArr.some(p => p === userPermission)));

    return (
        <Router hashHistory={HISTORY}>
            <Navbar bg="primary" fixed="top" className="justify-content-between">
                <NavContainer className="m-1">
                    {view === "closed" ?
                        <Nav.Item style={{ marginLeft: 0, }} className={'bars ' + expanded}>
                            <FontAwesomeIcon onClick={() => setView("open")} icon={faBars} aria-hidden="true"/>
                        </Nav.Item>
                        :
                        <Nav.Item style={{ marginLeft: '22vw', }} className={'bars ' + expanded}>
                            <FontAwesomeIcon onClick={() => setView("closed")} icon={faBars} aria-hidden="true"/>
                        </Nav.Item>
                    }
                    <Navbar.Brand className="text-light pt-1" href="/#/">
                        <span className="siteTitle">
                            <img style={{ height: '33px', marginY: '-15px' }} className="mr-2" alt="stop_sign" src={StopSign} />
                            Smart Dispensary Dashboard
                        </span>
                    </Navbar.Brand>
                    <DropdownButton title={<span className="mr-1"><FontAwesomeIcon icon={faUser} className="mr-2"/>{user.name}</span>} className="mt-0" menuAlign="right" variant="secondary">
                        <Dropdown.Item>{user.email}</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item><Button block onClick={() => logout({returnTo: window.location.origin})}>Logout</Button></Dropdown.Item>
                    </DropdownButton>
                </NavContainer>
            </Navbar>
            <>
                <Container>
                    <div className={'nav-con ' + expanded}>
                        <OpenContainer>
                            <div className={'userNav ' + expanded}>
                                <div className="mobile-close">
                                    <FontAwesomeIcon onClick={() => setView("closed")} icon={faBars} aria-hidden="true"/>
                                </div>
                                <DropdownButton title={currentShop} className="ml-4 mt-3">
                                    {(availableShops || []).map((shopId, idx) => <Dropdown.Item key={idx} onClick={_ => setShopId(shopId)}>{shopId}</Dropdown.Item>)}
                                </DropdownButton>
                            </div>
                            {shop && permissions &&
                                <ul>
                                    {hasPermission(['admin', 'products', 'inventory']) && shop.inventoryManagement === "greenstop" ?
                                        <li className="dropdownCon"><span className="create" onClick={() => { setCreateMenuOpen(!createMenuOpen) }}>Create {!createMenuOpen ? "+" : "-"}</span>
                                            {createMenuOpen ?
                                                <>
                                                    <Link className="dropdownItem" to="/category">Category</Link>
                                                    <Link className="dropdownItem" to="/product">Product</Link>
                                                    <Link className="dropdownItem" to="/backstockLocation">Backstock Location</Link>
                                                </>
                                                : null
                                            }
                                        </li>
                                        : null
                                    }
                                    <li><Link to="/openOrders">Open Orders</Link></li>
                                    <li><Link to="/preorders">Preorders</Link></li>
                                    {hasPermission(['admin', 'sales']) ?
                                        <li><Link to="/orders">Order Log</Link></li>
                                        : null
                                    }
                                    {hasPermission(['admin', 'sales']) ?
                                        <li><Link to="/sales">Sales</Link></li>
                                        : null
                                    }
                                    {hasPermission(['admin', 'customers']) ?
                                        <li><Link to="/customers">Customers</Link></li>
                                        : null
                                    }
                                    {hasPermission(['admin', 'kiosks']) ?
                                        <li><Link to="/kiosks">Kiosks</Link></li>
                                        : null
                                    }
                                    {hasPermission(['admin', 'products']) && shop.inventoryManagement === "greenstop" ?
                                        <li><Link to="/products">Products</Link></li>
                                        : null
                                    }
                                    {hasPermission(['admin', 'inventory']) ?
                                        <li><Link to="/inventory">Inventory</Link></li>
                                        : null
                                    }
                                    {hasPermission(['admin', 'packages']) && shop.inventoryManagement === "greenstop" && shop.metrcLicenseNumber && shop.metrcLicenseNumber.length ?
                                        <li><Link to="/packages">Packages</Link></li>
                                        : null
                                    }
                                    {hasPermission(['admin', 'invoices']) ?
                                        <li><Link to="/invoices">Invoices</Link></li>
                                        : null
                                    }
                                    {hasPermission(['admin']) ?
                                        <li><Link to="/settings">Settings</Link></li>
                                        : null
                                    }
                                    <li><Link to="/support">Support</Link></li>
                                </ul>
                            }
                        </OpenContainer>
                    </div>
                </Container>
            </>
            <AppContainer>
                <div className={'app ' + expanded}>
                    <Route path='/' exact render={(props) => <Home {...props} permissions={permissions} shop={shop} />} />
                    <Route path='/openOrders' exact render={(props) => <OpenOrders {...props} permissions={permissions} shop={shop} />} />
                    <Route path='/openOrders/:orderId' exact render={(props) => <HandleOrder {...props} permissions={permissions} shop={shop} />} />
                    <Route path='/preorders' exact render={(props) => <Preorders {...props} permissions={permissions} shop={shop} />} />
                    <Route path='/preorders/:preorderId' exact render={(props) => <PreorderDetails {...props} permissions={permissions} shop={shop} />} />
                    <Route path='/inventory' exact render={(props) => <Inventory {...props} permissions={permissions} shop={shop} />} />
                    <Route path='/packages' exact render={(props) => <Packages {...props} permissions={permissions} shop={shop} />} />
                    <Route path='/products' exact render={(props) => <Products {...props} permissions={permissions} shop={shop} />} />
                    <Route path='/category' exact render={props => <EditCategoryModal {...props} permissions={permissions} shop={shop} categoryIn={{}} afterSave={_ => props.history.push('/products')} hide={_ => props.history.goBack()} />} />
                    <Route path='/product' exact render={props => <EditProductModal {...props} permissions={permissions} shop={shop} productIn={{}} afterSave={_ => props.history.push('/products')} hide={_ => props.history.goBack()} />} />
                    <Route path='/backstockLocation' exact render={props => <EditBackstockLocationModal {...props} permissions={permissions} shop={shop} afterSave={_ => props.history.push('/inventory')} hide={_ => props.history.push('/')} />} />
                    <Route path='/sales' exact render={(props) => <AnalyzeSales {...props} permissions={permissions} shop={shop} />} />
                    <Route path='/customers' exact render={(props) => <CustomerLog {...props} permissions={permissions} shop={shop} />} />
                    <Route path='/customers/:customerId' exact render={(props) => <CustomerDetails {...props} permissions={permissions} shop={shop} />} />
                    <Route path='/orders' exact render={(props) => <OrderLog {...props} permissions={permissions} shop={shop} />} />
                    <Route path='/orders/:orderId' exact render={(props) => <OrderDetails {...props} permissions={permissions} shop={shop} />} />
                    <Route path='/kiosks' exact render={(props) => <KioskManagement {...props} permissions={permissions} shop={shop} />} />
                    <Route path='/kiosks/:terminalId' exact render={(props) => <TerminalDetails {...props} permissions={permissions} shop={shop} />} />
                    <Route path='/settings' exact render={(props) => <Settings {...props} permissions={permissions} shop={shop} />} />
                    <Route path='/invoices' exact render={(props) => <Invoices {...props} permissions={permissions} shop={shop} />} />
                    <Route path='/support' exact render={(props) => <Support {...props} />} />
                </div>
            </AppContainer>
        </Router>
    )
}

const AppContainer = styled.div`
    .app {  
        transition: width .2s linear;
    }
    .unexpanded{  // transition of 
        width: 78vw;
        float: right;
        display: inline-block;  
        padding-bottom: 30px;
    }
    .expanded{  // transition of 
        width: 100vw;
        float: right;
        display: inline-block;  
        padding-bottom: 30px;
    }
    @media (max-width: 715px) {
        .unexpanded{  // transition of 
            width: 100vw;
            float: right;
            display: inline-block;  
        }
        .expanded{  // transition of 
            width: 100vw;
            float: right;
            display: inline-block;  
        }
    }
`;

const NavContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 50px;
    .nav-link {
        color: #FFFFFF;
    }
    .bars {
        margin-right: 5px;
        margin-left: 15px;
        margin-top: 5px;
        vertical-align: middle;
        color: #FFFFFF !important;
        font-size: 30px;
        transition: margin-left .2s linear;
    }
    .bars:hover {
        transform: scale(1.15);
        cursor: pointer
    }
    .unexpanded{  // transition of 
        margin-left: 0;
    }
    .expanded{  // transition of 
        margin-left: 21vw; 
    }
    .navbar-nav {
        p {
            color: #FFFFFF;
            vertical-align: middle;
            margin-right: 15px;
            margin-bottom: 0;
        }
    }

`;

const OpenContainer = styled.div`
    background: linear-gradient(rgb(38,166,91, 1) 0%, rgb(38,166,91, 1) 20%, rgb(38,166,91, .7) 100%);
    color: white;
    top: 0;
    left: 0;
    min-height: 100vh;
    height: 100%;
    .userNav {
        text-align: center;
        background: #f3faf2;
        padding: 5px;
        height: 78px;
        transition: width .2s linear;
        p {
            margin-bottom: 2px;
            font-size: 20px;
            color: #000000;
        }
        button {
            width: 80%;
            background: #FFFFFF;
            color: rgb(38,166,91, 1);
            border: 0;
            font-size: 20px;
            padding: 2px;
        }
    }
    .unexpanded{  // transition of 
        width: 100%;
        @media (min-width: 715px) {
            .mobile-close {
                display: none;
            }
        }
    }
    .expanded{  // transition of 
        width: 0;
        background: #26A65B;
        padding: 0;
        .mobile-close {
            display: none;
        } 
        button {
            display: none;
        }
        p {
            display: none;
        }
    }
    ul li a {
        color: #FFFFFF;
        font-size: 24px;
        display: block;
        padding:6px;
    }
    ul li span {
        color: #FFFFFF;
        font-size: 24px;

    }
    ul li .dropdownItem {
        font-size: 16px;
        font-weight: bold;
        width: 85%;
        text-align: right;
        padding:6px;

    }
    ul .dropdownCon {

    }
    ul {
        margin-top: 25px;
        height: 80vh;
        padding-top: 15px;
        padding-bottom: 15px;
        list-style: none;
        padding: 1px;
        overflow-y: scroll;
    }
    ul li {
        width: 80%;
        text-align: center;
        font-size: 28px;
        margin: 0 auto;
    }
    .close {
        top: 0px;
        left: 0px;
        float: left;
        font-size: 24px;
        color: #FFFFFF;
        margin-left: 20px;
        opacity: 1;
    }
    span:hover {
        cursor: pointer;
    }
    ul li a:hover {
        text-decoration: none;
        transform: scale(1.15);
    }
    ul .dropdownCon:hover {
        text-decoration: none;
        transform: scale(1.1);

    }
    ul li .dropdownItem:hover {
        text-decoration: none;
        transform: scale(1.05);

    }
    ::-webkit-scrollbar {
        width: 10px;
    }
     
    ::-webkit-scrollbar-track {
        border-radius: 10px;
    }
     
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
        background-color: rgba(220,220,220, .6);
        opacity: .6;
    }
    @media (max-width: 715px) {
        .userNav {
            height: 120px;
            .mobile-close {
                font-size: 30px;
                display: inline-block;
                position: absolute;
                left: 0;
                top: 0;
                padding: 10px;
                color: rgb(38,166,91, 1);
            }
            p {
                margin-bottom: 10px;
                margin-top: 5px;
            }
            button {
                padding: 10px;
            }
        }   
    }

`;

const Container = styled.div`
    .nav-con {  
        transition: width .2s linear;
    }
    .unexpanded{  // transition of 
        width: 22vw;
        float: left;
        display: inline-block;  
    }
    .expanded{  // transition of 
        width: 0;
        float: left;
        display: inline-block;  
    }
    position: fixed;
    min-height: 100vh;
    height: 100%;
    padding-bottom: 60px;
    z-index: 1031;
    top: 0;
    left: 0;
    ul li a {
        color: #FFFFFF;
    }
    @media (max-width: 715px) {
        .unexpanded{  // transition of 
            width: 100vw;
            float: right;
            display: inline-block;  
        }
        .expanded{  // transition of 
            display: none;
            width: 0vw;

        }
    }

`;
