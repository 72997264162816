import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Link } from 'react-router-dom';


const OPEN_ORDERS_URL = '/orders/open';

export default function OpenOrders(props) {

    const [orders, setOrders] = useState([]);

    useEffect(() => {
        axios.get(OPEN_ORDERS_URL).then((response) => {
            setOrders(response.data);
        }).catch(console.error)
    }, []);


    return (
        <OrdersContainer>
        { orders &&
            <div>
                <h1>Open Orders</h1>
                {orders.map(order => {
                    let owed = !order.done ? order.amountPaid : order.change;
                    let textClass = (order.canceled && 'text-danger') || (order.done  && 'text-success') || 'text-warning';
                    
                    let message = '';
                    if (owed - order.changeDispensed > 0)  message = <p className="text-dark">Amount Owed: ${parseFloat(parseInt(owed - order.changeDispensed)).toFixed(2)}</p>
                    if (order.blazeUpdateError) message = <span className="text-danger">{order.blazeUpdateError.message}</span>;
                    if (order.treezUpdateError) message = <span className="text-danger">{order.treezUpdateError.message || order.treezUpdateError.resultDetail}</span>;
                    if (order.vendResults && order.vendResults.some(r => r && !r.success)) message = <span className="text-danger">Some products may have failed to vend </span>;
                    if (order.awaitingPickup) message = <span className="text-info">Some items have not been picked up</span>;
                    return (
                        <Link key={order._id} to={{ pathname: `/openOrders/${order._id}`}}>
                            <div className="orderName">
                                <p className={"customerName " + textClass}>{order.customer.name}</p>
                                { message }
                            </div>
                        </Link>
                    )}
                )}
            </div>
        }
        </OrdersContainer>
    )
}


const OrdersContainer = styled.div`
    h1 { 
        margin-top: 10px;
    }
    .orderName {
        display: flex;
        justify-content: space-between;
        width: 80%;
        padding: 10px;
        margin: 20px;
        margin-left: 10%;
        border: none;
        box-shadow: 0 0 3px rgba(0,0,0,0.4);
        border-radius: 5px;
        .customerName {
            text-align: left;
            font-weight: bold;
        }
        p {
            margin-bottom: 0;
        }
    }
    .orderName:hover {
        transform: scale(1.01);
        cursor: pointer;
    }


`;