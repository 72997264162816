import { useState, useEffect } from 'react';
import axios from 'axios';

const SEARCH_ORDERS_URL = '/sales';

export default function useOrders(queryParameters) {
    const [orders, setOrders] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const refreshOrders = _ => setRefresh(!refresh);

    useEffect(() => {
        if (!queryParameters) return;
        axios.post(
            SEARCH_ORDERS_URL, 
            queryParameters
        ).then(({ data }) => {
            setOrders(data || []);
        }
        ).catch(e => setOrders([]));
    }, [queryParameters, refresh]) 
    return [orders, refreshOrders]; 
}
