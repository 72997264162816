import React from 'react';
import {createRoot} from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import authConfig from './config/AUTH0.js';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const onRedirectCallback = appState => {
    console.log('App State:', appState)
};

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <Auth0Provider domain={authConfig.domain}
                    clientId={authConfig.clientId}
                    cacheLocation="localstorage"
                    onRedirectCallback={onRedirectCallback}
                    authorizationParams={{
                        redirect_uri:window.location.origin,
                        audience: authConfig.audience
                    }}>
        <App />
    </Auth0Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
