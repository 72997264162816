import React from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import Loading from './loading';
import HomeSalesDisplay from './homeSalesDisplay';
import getOrdersDateParams from '../common/getOrdersDateParams';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faCreditCard, faFileCircleCheck, faList, faTablet, faTruck, faUser } from '@fortawesome/free-solid-svg-icons';


const TILES = [
    {
        key: 'kiosks',
        name: 'Kiosks',
        path: '/kiosks',
        icon: faTablet
    },
    {
        key: 'sales',
        name: 'Sales',
        path: '/sales',
        icon: faCreditCard
    },
    {
        key: 'packages',
        name: 'Packages',
        path: '/packages',
        icon: faTruck,
        inventoryManagement: ['greenstop']
    },
    {
        key: 'inventory',
        name: 'Inventory',
        path: '/inventory',
        icon: faList,
        inventoryManagement: ['greenstop']
    },
    {
        key: 'customers',
        name: 'Customer Log',
        path: '/customers',
        icon: faUser,
        inventoryManagement: ['greenstop']
    },
    {
        key: 'orderlog',
        name: 'Order Log',
        path: '/orders',
        icon: faList
    },
    {
        key: 'invoices',
        name: 'Invoices',
        path: '/invoices',
        icon: faFileCircleCheck
    },
    {
        key: 'support',
        name: 'Support',
        path: '/support',
        icon: faCog
    }
]

const ordersDateParameters = getOrdersDateParams()

export default function Home({ shop, permissions }) {

    if (!permissions || !shop) return <Loading />

    const hasPermission = permissionArr => permissions && (permissions.some((userPermission) => permissionArr.some(p => p === userPermission)));

    const TodaysSales = hasPermission(['sales', 'admin']) ?
        <HomeSalesDisplay ordersDateParameters={ordersDateParameters}></HomeSalesDisplay>
        : null;

    let availableTiles = TILES
        .filter(tileInfo => hasPermission([tileInfo.key, 'admin']))
        .filter(tileInfo => !tileInfo.inventoryManagement || tileInfo.inventoryManagement.some(imType => shop.inventoryManagement === imType))
        .map(tileInfo =>
            <Card className="bg-light p-4 menuTile">
                <Link className="font-weight-bold" to={tileInfo.path}>
                    <FontAwesomeIcon icon={tileInfo.icon} size='5x'/>
                    <br />
                    {tileInfo.name}
                </Link>
            </Card>
        );
    const menuTiles = availableTiles.map((tile, idx) =>
        <Col md={3} key={idx}>
            {tile}
        </Col>
    );

    return (
        <HomeContainer className="mt-4">
            <Container>
                {TodaysSales}
                <Row className="mt-0 mx-1">
                    {menuTiles}
                </Row>
            </Container>
        </HomeContainer>
    )
}

const HomeContainer = styled.div`
    .card {
        border: 0;
        box-shadow: 0px 3px 15px rgba(0,0,0,0.3);
    }
    .menuTile:hover {
        background-color: #26A65B !important;
        a {
            color: white;
            text-decoration: none;
        }
    }
    .flushCard {
        margin-top: 45px;
        margin-bottom: 0;
    }
    @media(max-width: 1199px) {
        .flushCard {
            margin-top: 0px;
        }
    }
`;
