import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import styled from 'styled-components';
import Loading from '../loading';
import ReactTable from 'react-table-6';
import OrderModal from '../modals/orderModal';
import useOrders from '../../hooks/useOrders';

export default function ViewSales({ ordersDateParameters }) {
    const [firstRender, setFirstRender] = useState(true)
    const [offset, setOffset] = useState(0);


    const [queryParameters, setQueryParameters] = useState()

    useEffect(() => {
        setQueryParameters({
            ...ordersDateParameters,
            offset,
            sortBy : {'timestamp' : -1},
            limit : 10
        })
    }, [ordersDateParameters, offset])

    const [orders] = useOrders(queryParameters)

    if (!orders) return <Loading />;

    if (orders.results.length >= 1) {

        const columns = [
            {
                Header: 'Order Details',
                id: 'orderDetails',
                accessor: o => o,
                Cell: o => <OrderModal order={o.value} />
            },
            {
                Header: 'Date',
                id: 'orderDate',
                accessor: o => DateTime.fromISO(o.timestamp).toLocaleString(DateTime.DATETIME_FULL)
            },
            {
                Header: 'Terminal ID',
                id: 'terminalId',
                accessor: o => o.createdTerminalId ? `Side ${o.createdTerminalId.split('-t')[1]}` : 'Unknown'
            },
            {
                Header: 'Customer',
                id: 'customerName',
                accessor: o => o.customer && o.customer.name
            },
            {
                Header: 'Products Vended',
                id: 'productsVended',
                accessor: o => o.items.reduce((total, current) => total + (current.quantity), 0)
            },
            {
                Header: 'Total',
                id: 'total',
                accessor: o => o.total.toFixed(2)
            }]

        return (
            <TableContainer>
                <ReactTable 
                    data={orders.results}
                    columns={columns}
                    className='-striped -highlight table'
                    pages={Math.ceil(orders.count / 10)}
                    defaultSorted={[
                        {
                          id: "orderDate",
                          desc: true
                        }
                      ]}
                    defaultPageSize={10}
                    pageSizeOptions={[10]}
                    manual
                    onFetchData={(state, instance) => {
                        (firstRender) ? setFirstRender(false) : setOffset(state.page * 10);
                    }}
                />
            </TableContainer>
        )
    } else {
            return (
                <NoView>No orders for selected time period</NoView>
            )
    }
}

const NoView = styled.h2`
    font-weight: bold;
    font-size: 48px;
    margin-top: 10%;
    text-align: center;
    width: 100%;
`;

const TableContainer = styled.div`
    padding: 0;
    overflow: hidden;
    .table {
        max-height: 100%;
        min-height: 100%;
    }
    @media(max-width: 928px) {
        height: calc(100vh - 180px);
    }
    @media(max-width: 643px) {
        height: calc(100vh - 220px);
    }
    @media(max-width: 538px) {
        height: calc(100vh - 250px);
    }
    @media(max-width: 528px) {
        height: calc(100vh - 300px);
    }
    @media(max-width: 500px) {
        height: calc(100vh - 220px);
    }
    @media(max-width: 364px) {
        height: calc(100vh - 270px);
    }
`;

