import Table from 'react-bootstrap/Table';
import groupSalesByProductId from '../components/sales/salesGroupByProductID';

export default function OrderItemsTable({order}) {
    return <Table striped bordered hover variant className='mb-0'>
        <thead>
            <tr>
                <th>Product</th>
                <th>Side</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Total</th>
            </tr>
        </thead>
        <tbody>
        {(groupSalesByProductId(order)).map((item, index) => (
            <tr key={item._id}>
            <td className={item.quantityVended === item.quantity ? 'text-success' : order.done && item.terminalId===order.createdTerminalId ? 'text-info' : ''}>{item.name}</td>
            <td>{item.sides}</td>
            <td>{item.quantity}</td>
            <td>{item.price.toFixed(2)}</td>
            <td>{(item.quantity * item.price).toFixed(2)}</td>
            </tr>
        ))}
        </tbody>
  </Table>
}