import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/esm/Row';
import axios from 'axios';
import Loading from './loading';

const TIPS_URL = '/tips'

export default function Tips({ ordersDateParameters }) {

    const [tipSummary, setTipSummary] = useState();

    useEffect(_ => {
        axios.post(TIPS_URL, ordersDateParameters).then(resp => setTipSummary(resp.data))
    }, [ordersDateParameters])

    return ( tipSummary ? 
        <Row className="mt-0 ml-1 mr-1 pt-2" >
            <Col lg={3} className="pt-2" >
                <Card>
                    <h1 style={{ color: '#26A65B', }}>Total Tips: </h1>
                    <h2>${tipSummary.total.toFixed(2)}</h2>
                </Card>
            </Col>
            <Col lg={3} className="pt-2" >
                <Card>
                    <h1 style={{ color: '#26A65B', }}>Cash Tips: </h1>
                    <h2>${tipSummary.cash.toFixed(2)}</h2>
                </Card>
            </Col>
            <Col lg={3} className="pt-2">
                <Card>
                    <h1 style={{ color: '#26A65B', }}>Card Tips: </h1>
                    <h2>${tipSummary.card.toFixed(2)}</h2>
                </Card>
            </Col>
            <Col lg={3} className="pt-2">
                <Card>
                    <h1 style={{ color: '#26A65B', }}>Average Tip: </h1>
                    <h2>${tipSummary.average?.toFixed(2)}</h2>
                </Card>
            </Col>
        </Row> :
        <Loading/>
    )
}

