import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Loading from './loading';
import ReactTable from 'react-table-6';
import styled from 'styled-components';
import Button from 'react-bootstrap/esm/Button';
import Breadcrumb from 'react-bootstrap/esm/Breadcrumb';
import Container from 'react-bootstrap/esm/Container';

const CUSTOMERS_URL = '/customers';
export default function CustomerLog(props) {

    const [customers, setCustomers] = useState([]);

    let permissions = props.permissions;
    
    useEffect(() => {
        axios.get(CUSTOMERS_URL).then(resp => resp.data || []).then(customers => setCustomers(customers.filter(c=>c))).catch(err => {
            console.error(err);
        });
    }, []);

    const filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        const content = row[id];
        if (typeof content !== 'undefined') {
            // filter by text in the table or if it's a object, filter by key
            if (typeof content === 'object' && content !== null && content.key) {
                return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
            } else {
                return String(content).toLowerCase().includes(filter.value.toLowerCase());
            }
        }
    
        return true;
    };

    const columns = [
        {
            Header: 'Details',
            id: 'orderDetails',
            accessor: p => p,
            Cell: p => <Button href={`#/customers/${p.original._id}`}>View Customer</Button>,
            width: 140
        },
        {
            Header: 'Customer Name',
            id: 'customerName',
            accessor: p => p.name
        },
        {
            Header: 'Email',
            id: 'customerEmail',
            accessor: p => p.email
        },
        {
            Header: 'Cell',
            id: 'customerCell',
            accessor: p => p.cell
        },
        {
            Header: 'License Type',
            id: 'customerLicenseType',
            accessor: p => p.licenseType
        },
        {
            Header: 'License Number',
            id: 'customerLicenseNumber',
            accessor: p => p.driversLicenseNumber
        }]
    
        if (customers.length === 0) return <Loading />;
        if(permissions && (permissions.some(per => per === 'customers') || permissions.some(per => per === 'admin'))) {
        return (
            <Container>
                <Breadcrumb className="mt-3">
                    <Breadcrumb.Item active>Customers</Breadcrumb.Item>
                </Breadcrumb>
                <TableContainer>
                    <ReactTable 
                        data={customers}
                        columns={columns}
                        className='-striped -highlight table'
                        defaultSorted={[
                            {
                                id: "orderDate",
                                desc: true
                            }
                            ]}
                        defaultPageSize={10}
                        pageSizeOptions={[10]}
                        filterable={true}
                        defaultFilterMethod={filterCaseInsensitive}
                    />
                </TableContainer>
            </Container>
        )
    }
}

const TableContainer = styled.div`
    padding: 0;
    overflow: hidden;
    .table {
        max-height: 100%;
        min-height: 100%;
    }
    @media(max-width: 928px) {
        height: calc(100vh - 180px);
    }
    @media(max-width: 643px) {
        height: calc(100vh - 220px);
    }
    @media(max-width: 538px) {
        height: calc(100vh - 250px);
    }
    @media(max-width: 528px) {
        height: calc(100vh - 300px);
    }
    @media(max-width: 500px) {
        height: calc(100vh - 220px);
    }
    @media(max-width: 364px) {
        height: calc(100vh - 270px);
    }
`;

