import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Loading from './loading';
import ReactTable from 'react-table-6';
import styled from 'styled-components';
import Breadcrumb from 'react-bootstrap/esm/Breadcrumb';
import Container from 'react-bootstrap/esm/Container';
import Table from 'react-bootstrap/esm/Table';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { DateTime } from 'luxon';
import OrderModal from '../components/modals/orderModal';
import SalesSummaryCustomer from './sales/salesSummaryCustomer';

export default function CustomerDetails(props) {
    const [orders, setOrders] = useState();
    const [customer, setCustomer] = useState();

    useEffect(() => {
        axios.get(`/customer/${props.match.params.customerId}/orders`).then(({ data }) => {
            setOrders(data);
        }).catch(console.error);
    }, [props.match.params.customerId]);

    useEffect(() => {
        axios.get(`/customer/${props.match.params.customerId}`).then(({ data }) => {
            setCustomer(data);
        }).catch(console.error);
    }, [props.match.params.customerId]);

    const columns = [
        {
            Header: 'Details',
            id: 'orderDetails',
            accessor: p => p,
            Cell: p => <OrderModal order={p.value}/>,
            width: 140
        },
        {
            Header: 'Status',
            id: 'status',
            accessor: p => {
                if (p.done) return <span className="text-success">Vended</span>
                if (p.canceled) return <span className="text-danger">Canceled</span>
                return <span className="text-warning">In Progress</span>
            }
        },
        {
            Header: 'Order Date',
            id: 'orderDate',
            accessor: 'timestamp',
            Cell: props => <span>{DateTime.fromISO(props.value).toLocaleString(DateTime.DATETIME_FULL)}</span>
        },
        {
            Header: 'Terminal ID',
            id: 'terminalId',
            accessor:p => p.terminalId ? `Side ${p.terminalId.split('-t')[1]}` : 'Unknown'
        },
        {
            Header: 'Products Vended',
            id: 'countProductsVended',
            accessor: p => (p.vendResults && p.vendResults.length) || 0,
            width: 140
        },
        {
            Header: 'Total',
            id: 'total',
            accessor: p => p.total.toFixed(2),
            width: 100
        }]

    if (!orders || !customer) return <Loading />;
    return (
        <Container>
            <Breadcrumb className="mt-3">
                <Breadcrumb.Item href="#/customers">Customers</Breadcrumb.Item>
                <Breadcrumb.Item active>{customer.name}</Breadcrumb.Item>
            </Breadcrumb>
            <Row className="mt-1 mb-3 align-items-center">
            <Col className="pt-0" md={4}>
            <Table striped bordered hover variant className='mb-0' size="sm">
                <tbody>
                    <tr>
                        <td className="font-weight-bold">Name</td>
                        <td>{customer.name}</td>
                    </tr>
                    <tr>
                        <td className="font-weight-bold">DOB</td>
                        <td>{customer.dob ? customer.dob.substring(0, 10) : ""}</td>
                    </tr>
                    <tr>
                        <td className="font-weight-bold">License</td>
                        <td>{customer.driversLicenseNumber}</td>
                    </tr> 
                    <tr>
                        <td className="font-weight-bold">Email</td>
                        <td>{customer.email}</td>
                    </tr>
                    <tr>
                        <td className="font-weight-bold">Cell</td>
                        <td>{customer.cell}</td>
                    </tr>
                </tbody>
            </Table>
            </Col>
            <Col className="pt-0">
                <SalesSummaryCustomer orders={orders.filter(o => o.done)} />
            </Col>
            </Row>
            <TableContainer>
                <ReactTable
                    data={orders}
                    columns={columns}
                    className='-striped -highlight table'
                    defaultSorted={[
                        {
                            id: "orderDate",
                            desc: true
                        }
                    ]}
                    defaultPageSize={6}
                    pageSizeOptions={[6]}
                />
            </TableContainer>
        </Container>
    )
}

const TableContainer = styled.div`
    padding: 0;
    overflow: hidden;
    .table {
        max-height: 100%;
        min-height: 100%;
    }
    @media(max-width: 928px) {
        height: calc(100vh - 180px);
    }
    @media(max-width: 643px) {
        height: calc(100vh - 220px);
    }
    @media(max-width: 538px) {
        height: calc(100vh - 250px);
    }
    @media(max-width: 528px) {
        height: calc(100vh - 300px);
    }
    @media(max-width: 500px) {
        height: calc(100vh - 220px);
    }
    @media(max-width: 364px) {
        height: calc(100vh - 270px);
    }
`;



