import { useState, useEffect } from 'react';
import axios from 'axios';

const SEARCH_ORDERS_URL = '/sales/analytics';

export default function useOrdersAnalytics(queryParameters, analyticType) {
    const [ordersAnalytics, setOrdersAnalytics] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const refreshOrdersAnalytics = _ => setRefresh(!refresh);

    useEffect(() => {
        console.log(analyticType)
        if (!analyticType) return;
        let analytic = analyticType;
        if (analyticType === 'salesByTimePeriod') {
            if (queryParameters.startDate.slice(0, 10) === queryParameters.stopDate.slice(0, 10)) analytic = 'salesByHour';
            else analytic = 'salesByDay';
            setOrdersAnalytics(null);
        }
        const query = {startDate : queryParameters.startDate, stopDate : queryParameters.stopDate, analytic}

        axios.post(
            SEARCH_ORDERS_URL, 
            query
        ).then(({ data }) => {
            setOrdersAnalytics(data.results || []);
        }
        ).catch(e => setOrdersAnalytics([]));
    }, [queryParameters, analyticType, refresh])
    return [ordersAnalytics, refreshOrdersAnalytics]; 
}
