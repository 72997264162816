import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Axios from 'axios';
import Form from 'react-bootstrap/Form';
import Loading from './loading';

const SUPPORT_FOOTAGE_URL = '/support';

function Support() {
	
	const [copied, setCopied] = useState(false);
	const [videos, setVideos] = useState(null);
	const [selectedVideo, setSelectedVideo] = useState('');

	const [pdfs, setPdfs] = useState({});
 
	useEffect(() => {
		Axios.get(SUPPORT_FOOTAGE_URL).then(({ data }) => {
			let videoMap = data.video;
			setVideos(videoMap);
			setSelectedVideo(Object.keys(videoMap)[0] || '')

			let pdfMap = data.pdf;
			setPdfs(pdfMap);
		}).catch(console.error)
	}, []);

	if (!videos) return <Loading/>
	
	// Test attempt programmatically creating all support videos at once
	let supportVideos =
		<Form.Control id="videoSelect" as="select" value={selectedVideo} onChange={e => setSelectedVideo(e.target.value)}>
			{Object.keys(videos).map((key, idx) => <option key={idx} value={key}>{key.replace(/([A-Z])/g, ' $1').trim()}</option> )}
		</Form.Control>

	return (
		<SupportPage>
			<h1>Support Links</h1>
			<EmailLinks>
				<CopyToClipboard
					text="incoming+greenstop-llc-kiosk-12756452-issue-@incoming.gitlab.com"
					onCopy={() => setCopied(true)}>
					<button>{ !copied ? 'Copy Support Email to Clipboard' : 'Copied' }</button>
				</CopyToClipboard>
			</EmailLinks>
			{
				Object.keys(pdfs).map((key, idx) =>
					<h3 key={idx}>
						<a target="_blank" rel="noopener noreferrer" href={pdfs[key]}>{key.replace('GS_SOP_', 'StandardOperatingProcedure:').replace(/([A-Z])/g, ' $1').trim()}</a>
					</h3>
				)
			}
			<SupportContainer>
				<h1 style={{marginTop: '100px'}}>Videos</h1>
				{ supportVideos }
				<div style={{ display: 'block', float: 'none', paddingBottom: '50px' }} preload="metadata">
					<h3>{selectedVideo.replace(/([A-Z])/g, ' $1').trim()}</h3>
					<video src={videos[selectedVideo]+'#t=10'} width="640" controls></video>
				</div>
				<h1>Virtual Reality Videos</h1>
				<h2>VR of Smart Dispensary POV</h2>
				<iframe title="VR of Smart Dispensary POV" id="VRofSmartDispensaryPOV" width="560" height="315" src="https://www.youtube.com/embed/Z_fWTwqDwQ0" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
				<h2>VR of Smart Dispensary multiple users </h2>
				<iframe title="VR of Smart Dispensary multiple users" id="VRofSmartDispensaryMultipleUsers" width="560" height="315" src="https://www.youtube.com/embed/Bd4PUWua4Kw" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
				<h2>VR Transaction Walk through </h2>
				<iframe title="VR Transaction Walk through" id="VRTransactionWalkThrough" width="560" height="315" src="https://www.youtube.com/embed/t_nifut_w9w" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
			</SupportContainer>
		</SupportPage>
	)
}

export default Support;

const SupportPage = styled.div`
	width: 100%;
	margin-top: 100px;
	h1 {
		margin-top: 25px;
		margin-bottom: 25px;
	}
	h3 {
		text-align: left;
		margin-left: 10%;
	}
`;

const EmailLinks = styled.div`
	button {
		position: absolute;
		right: 20px;
		top: 120px;
		color: #FFFFFF;
		padding: 10px 15px;
		background: #26A65B;
		border-radius: 5px;
		border: 0;
	}

`;

const SupportContainer = styled.div`
	width: 80%;
	margin: 0 auto;
	padding-bottom: 40px;
	h2{
		margin-top: 25px;
		margin-bottom: 10px;
	}
`;
