import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import styled from 'styled-components';
import Loading from './loading';

export default function TerminalDetails(props) {
    const [inventory, setInventory] = useState();
    const [terminal, setTerminal] = useState();

    useEffect(_ => {
        axios.get('/terminal/' + props.match.params.terminalId + '/inventory').then(resp => {
            setInventory(resp.data);
        })
        axios.get('/terminal/' + props.match.params.terminalId).then(resp => {
            setTerminal(resp.data);
        })
    }, [props.match.params.terminalId])
console.log(terminal)
    if (!inventory || !terminal) return <Loading/>

    return (
        <Container>
            <h1 className="mb-0 mt-4">{terminal.kioskName + ' - ' + terminal.name}</h1>
            <Row className="mt-0 mx-3">
                {inventory.map((product, idx) => <Product key={idx} product={product}/>)}
            </Row>
        </Container>
    )
}

function Product(props) {
    const product = props.product;
    return (
        <Col sm={6} lg={4} xl={3}>
            <CardBlock>
                <Card className="product">
                    <Card.Header className="p-0">
                        <div style={{ display: 'relative' }}>
                            <Card.Img style={{height: '240px'}} src={product.imgSmall || product.img} alt="product" />
                            { product.cannabisType === "sativa" ?
                                    <Dot style={{border: '3px solid #FF722C', backgroundColor: '#FF722C',}}>
                                    <p>SATIVA</p>
                                    </Dot>
                                : product.cannabisType === "indica" ?
                                    <Dot style={{border: '3px solid #922CFF', backgroundColor: '#922CFF',}}>
                                    <p>INDICA</p>
                                    </Dot>
                                : product.cannabisType === "hybrid" ?
                                    <Dot style={{border: '3px solid #02E81A', backgroundColor: '#02E81A',}}>
                                    <p>HYBRID</p>
                                    </Dot>
                                : product.cannabisType === "cbd" ?
                                    <Dot style={{border: '3px solid #FFD700', backgroundColor: '#FFD700',}}>
                                    <p>CBD</p>
                                    </Dot>
                                : <Dot style={{border: '3px solid #FFFFFF', backgroundColor: '#FFFFFF',}}/>
                            }
                        </div>
                    </Card.Header>
                    <Card.Body className="info pt-0">
                        <Card.Title>{product.name}</Card.Title>
                        <span className="fa fa-info"></span>
                        <div className="d-flex justify-content-between">
                            <InlineBlock>
                                ${
                                    product.amount === 1 ? `${product.price} / ${product.unit}`
                                    : product.price === 0 ? `${product.price}`
                                    : `${product.price} for ${product.amount} ${product.unit}s`
                                }
                            </InlineBlock>
                            <InlineBlock>Stock: {product.stock}</InlineBlock>
                        </div>
                    </Card.Body>
                </Card>
            </CardBlock>
        </Col>
    )
}

const InlineBlock =  styled.div`
    display: inline-block;
`

const Dot = styled.div`
  p {
      height: 100%;
      width: 100%;
      margin-bottom: 0;
      text-align: center;
      display: inline-block;
      vertical-align: top;
      margin-top: -3px;
      color: #FFFFFF;
      font-size: 20px;
      font-weight: bold;
  }
  display: inline-block;
  position: relative;
  height: 30px;
  top: -15px;
  width: 100%;
  z-index:900;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
`;


const CardBlock = styled.div`
  margin: 0.4em;
  margin-bottom: 30px;
  border: none;
  position: relative;
  box-shadow: 0px 3px 15px rgba(0,0,0,0.6);
  .product {
    border: none;
  }
  .card-body {
    padding-bottom: 10px;
  }
  .info {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    border-right: 1px solid rgba(0, 0, 0, 0.125);
    border-left: 1px solid rgba(0, 0, 0, 0.125);
    .card-title {
      display: inline-block;
      width: 70%;
      line-height: 21px;
      max-height: 42px;
      min-height: 42px;
      vertical-align: middle;
      margin-bottom: 5px;
      overflow:hidden;
    }
    span {
       display: inline-block;
       width: 25%;
       text-align: right;
       vertical-align: top;
       margin-top: 5px;
    }
  }
  .card-header{
    border: none;
    background-color: #FFFFFF;
  }
  .price {
    margin-top: 10px;
  }
`;