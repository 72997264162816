import React, { useCallback, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';


const LOCATION_URL = '/backstock';

export default function EditBackstockLocationModal({ hide, afterSave, locationIn }) {

    const [location, setLocation] = useState(locationIn || {});
    const [disabled, setDisabled] = useState(false);
    const setLocationName = name => {
        setLocation({
            name,
            key: name.replace(/\s+/g, '-').toLowerCase()
        })
    }

    const saveLocation = useCallback(location => {
        setDisabled(true);
        axios.put(LOCATION_URL, location).then(({ data }) => {
            afterSave(data);
            hide();
            setDisabled(false);
        }).catch(error => error);
    }, [afterSave, hide]);

    return (
        <Modal show={true} className='backstockModal' onHide={hide}>
            <Modal.Header closeButton>
                <Modal.Title>{location._id ? 'Edit' : 'Create'} Location:</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={e => console.log(e.currentTarget.value)}>
                    <Form.Group controlId="name">
                        <Form.Label>Location Name</Form.Label>
                        <Form.Control type="text" placeholder="Location Name" value={location.name || ''} onChange={e => setLocationName(e.target.value)} />
                    </Form.Group>
                    <Form.Group controlId="key">
                        <Form.Label>Location Key</Form.Label>
                        <Form.Control disabled type="text" placeholder="Location Key" value={location.key || ''} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={hide}>Cancel</Button>
                <Button variant="primary" disabled={disabled} onClick={() => saveLocation(location)}>Save changes</Button>
            </Modal.Footer>
        </Modal>
    )
}
