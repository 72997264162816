import React, { useState, useEffect } from 'react';
import axios from 'axios';
import OrderItemsTable from './orderItemsTable';
import Loading from './loading';
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

export default function PreorderDetails(props) {

    const [preorder, setPreorder] = useState();

    useEffect(() => {
        (async () => {
            const result = await axios.get(`/preorders/${props.match.params.preorderId}`);
            let preorder = result.data;
            preorder.products = preorder.items;
            setPreorder(preorder)
        })();
    }, [props.match.params.preorderId]);

    return preorder ? <Container>
        <Breadcrumb className="mt-3">
            <Breadcrumb.Item href={`#/preorders`}>Preorders</Breadcrumb.Item>
            <Breadcrumb.Item active>{props.match.params.preorderId}</Breadcrumb.Item>
        </Breadcrumb>
        <h2 className='float-left'>Email: {preorder.email}</h2>
        <OrderItemsTable order={preorder} />
    </Container> : <Loading/>
}