import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Loading from './loading';
import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { DateTime } from 'luxon';
import papa from 'papaparse';

const INVOICES_URL = '/invoices';
const INVOICE_URL = '/invoice';

function download(filename, text) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}


const generateCSV = async invoiceId => {
    let invoice = await axios.get(INVOICE_URL + '/' + invoiceId).then(resp => resp.data)
    let total = 0;
    let subTotal = 0;
    let totalDiscount = 0;
    let totalTax = 0;
    let breakdown = invoice.orders.map(order => {
        total += order.total;
        subTotal += order.subTotal;
        totalDiscount += order.discount || 0;
        totalTax += order.tax || 0;

        let feeObj = {};
        if (invoice.monthlyLeaseFee) feeObj['Lease Fee'] = '-';
        if (invoice.serviceRate) feeObj[`Fee (${invoice.serviceRate}%)`] = `$${(invoice.serviceRate * (order.subTotal - (order.discount || 0))).toFixed(2)}`;
        if (invoice.transactionFeeConfig) feeObj['Transaction Fee'] = `$${(order.transactionFee).toFixed(2)}`;

        return {
            ...feeObj,
            "Sub Total": `$${order.subTotal.toFixed(2)}`,
            "Discount": `$${(order.discount || 0).toFixed(2)}`,
            "Tax": `$${(order.tax || 0).toFixed(2)}`,
            "Total": `$${order.total.toFixed(2)}`,
            "Date": DateTime.fromJSDate(new Date(order.timestamp)).toFormat('f'),
            "Customer ID": order.customerId,
            "Order Id": order._id,
            "Products": order.items.map(p => `${p.name} (${p.quantity} X $${p.price})`)
        }
    })

    breakdown.push({})
    let feeObj = {}
    if (invoice.serviceRate) feeObj[`Fee (${invoice.serviceRate}%)`] = `$${(invoice.royaltiesOwed).toFixed(2)}`;
    if (invoice.transactionFeeConfig) feeObj['Transaction Fee'] = `$${(invoice.transactionFeesOwed).toFixed(2)}`;
    if (invoice.monthlyLeaseFee) feeObj['Lease Fee'] = `$${(invoice.monthlyLeaseFee).toFixed(2)}`;
    breakdown.push({
        ...feeObj,
        "Sub Total": `$${subTotal.toFixed(2)}`,
        "Discount": `$${(totalDiscount).toFixed(2)}`,
        "Tax": `$${(totalTax || 0).toFixed(2)}`,
        "Total": `$${total.toFixed(2)}`
    })

    let csvStr = papa.unparse(breakdown);
    download('Greenstop Invoice - ' + DateTime.fromJSDate(new Date(invoice.startTime)).toFormat('MMMM y') + '.csv', csvStr)
}

export default function Invoices(props) {

    const [invoices, setInvoices] = useState(null);
    const [paid, setPaid] = useState(null);
    let permissions = props.permissions;

    useEffect(() => {
        axios.get(INVOICES_URL).then(({ data }) => {
            let paid = [];
            let unpaid = []
            data.map(a => a.paid ? paid.push(a) : unpaid.push(a));
            paid.sort((a, b) => new Date(b.startTime) - new Date(a.startTime));
            setInvoices(unpaid);
            setPaid(paid);
        }).catch(console.error)
    }, [])

    return (
        permissions && permissions.some(per => ['admin', 'invoices'].includes(per)) ?
            <div>
                {invoices && paid ?
                    <InvoiceContainer>
                        <Container style={{ marginBottom: '30px' }}>
                            <Row style={{ background: '#ffffff' }}>
                                { !invoices.isEmpty || !paid.isEmpty ?
                                    invoices.concat(paid).map(invoice =>
                                        <Col key={invoice._id} sm={6} >
                                            <CardContainer>
                                                <Card>
                                                    <Card.Header style={{ backgroundColor: invoice.paid ? '#26A65B' : '#DC3545' }}>
                                                        {invoice.name} - { DateTime.fromJSDate(new Date(invoice.startTime)).plus({days: 1}).toLocaleString({month: 'long'}) }
                                                        <Button variant="outline-light" className="mt-0 float-right" onClick={() => generateCSV(invoice._id)}>Download Invoice</Button>
                                                    </Card.Header>
                                                    <p className="timestamp">{DateTime.fromJSDate(new Date(invoice.startTime)).toLocaleString(DateTime.DATE_MED)} - {DateTime.fromJSDate(new Date(invoice.endTime)).toLocaleString(DateTime.DATE_MED)}</p>
                                                    <Card.Body>
                                                        <p>Total Sales:</p><p className="cost"> ${parseFloat(invoice.totalSales).toFixed(2)}</p>
                                                        {
                                                            invoice.serviceRate ?
                                                                <><p>Service Fee:</p><p className="cost"> ${parseFloat(invoice.royaltiesOwed).toFixed(2)} ({parseFloat((invoice.royaltiesOwed / invoice.totalSales) * 100).toFixed(2)}%)</p></>
                                                                : null
                                                        }
                                                        {
                                                            invoice.transactionFeesOwed ?
                                                                <><p>Transaction Fees:</p><p className="cost"> ${parseFloat(invoice.transactionFeesOwed).toFixed(2)}</p></>
                                                                : null
                                                        }
                                                        {
                                                            invoice.monthlyLeaseFee ?
                                                                <><p>Monthly Lease Fee:</p><p className="cost"> ${parseFloat(invoice.monthlyLeaseFee).toFixed(2)}</p></>
                                                                : null
                                                        }
                                                        <p style={{ fontWeight: 'bold' }}>Total:</p><p style={{ fontWeight: 'bold' }} className="cost"> ${parseFloat(invoice.totalOwed).toFixed(2)}</p>
                                                        <p>Total orders: {invoice.numberOfOrders}</p>
                                                        <p style={{ textAlign: 'right' }}>Average order cost: ${parseFloat(invoice.totalOwed / invoice.numberOfOrders || 0).toFixed(2)}</p>
                                                        {invoice.stripeInvoice &&
                                                            <div>
                                                                { invoice.stripeInvoice.status === 'draft' ?
                                                                    <small className='text-danger'>Processing invoice, come back in ~1hr to pay!</small>
                                                                    :
                                                                    <>
                                                                        <a target="_blank" rel="noopener noreferrer" href={invoice.stripeInvoice.invoice_pdf || null}><Button variant="outline-primary">PDF</Button></a>
                                                                        {!invoice.paid && <a target="_blank" rel="noopener noreferrer" href={invoice.stripeInvoice.hosted_invoice_url || null}><Button variant="outline-primary" className="ml-2">Pay Now</Button></a>}
                                                                    </>
                                                                }
                                                            </div>
                                                        }
                                                    </Card.Body>
                                                </Card>
                                            </CardContainer>
                                        </Col>
                                    ) : <h1>No invoices found</h1> }
                                </Row>
                            </Container>
                    </InvoiceContainer>
                    :
                    <Loading />
                }
            </div>
            :
            <h1 style={{ marginTop: '30px', }}>You do not have access to invoices</h1>
    )
}

const InvoiceContainer = styled.div`
    justify-content: space-between;
    display: flex;
    text-align: left;
`;




const CardContainer = styled.div`
    width: 100%;
    justify-content: space-between;
    .card {
        border: 0;
        box-shadow: 0 0 5px rgba(0,0,0,0.4);
    }
    .card-header {
        border: 0;
        font-weight: bold;
        color: #FFFFFF;
        margin-bottom: 15px;
    }
    .card-body {
        background-color: #ffffff;
    }
    .card-footer {
        background-color: #ffffff;
        border: 0;
    }
    .product-header {
        // box-shadow: 0 0 10px rgba(0,0,0,0.4);
        font-weight: bold;
        width: 75%;
        margin: 0 auto;
        margin-top: 15px;
        margin-bottom: 15px !important;
    }
    .product-breakdown{
        border: 0;
        .product-header:hover{ 
            transform: scale(1.1);
            cursor: pointer;
        }
    }
    p { 
        width: 50%;
        display: inline-block;
        text-align: left;
    }
    .cost { 
        text-align: right;
    }
    .total { 
        width: 100%;
        text-align: center;
    }
    .timestamp {
        background-color: #ffffff;
        border: 0;
        text-align: center;
        width: 100%;
    }
    .download {
        text-align: left;
        margin-bottom: 10px;
        margin-top: 10px;
        .total { 
            text-align: left;
        }
        p {
            width: 100%;
            margin: 0;
        }
    }
`;
