import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';

import axios from 'axios';
import { toast } from 'react-toastify';
import Col from 'react-bootstrap/esm/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import Navbar from 'react-bootstrap/esm/Navbar';

const NOTIFICATION_SETTINGS_URL = '/settings/notifications';

const NOTIFICATION_TYPES = ['bill']

export default function NotificationSettings({currentShopSettings, refreshCurrentShopSettings}) {

    const [notificationEmailConfigs, setNotificationEmailConfigs] = useState([]);
    const [sendNotificationEmails, setSendNotificationEmails] = useState(currentShopSettings.sendNotificationEmails || false);
    const [disableSubmit, setDisableSubmit] = useState(false);

    useEffect(_ => {
        setSendNotificationEmails(currentShopSettings.sendNotificationEmails);
        setNotificationEmailConfigs(currentShopSettings.notificationEmailConfigs);
    }, [currentShopSettings]);


    let updateNotificationEmail = (idx, email) => {
        let newNotifcationEmailConfigs = [...notificationEmailConfigs];
        if(!newNotifcationEmailConfigs[idx]) newNotifcationEmailConfigs[idx] = {email: '', notificationTypes: {}};
        newNotifcationEmailConfigs[idx].email = email;
        setNotificationEmailConfigs(newNotifcationEmailConfigs);
    }

    let updateNotificationType = (idx, type, value) => {
        console.log(idx)
        let newNotifcationEmailConfigs = [...notificationEmailConfigs];
        if(!newNotifcationEmailConfigs[idx]) newNotifcationEmailConfigs[idx] = {email: '', notificationTypes: {}};
        newNotifcationEmailConfigs[idx].notificationTypes[type] = value;
        setNotificationEmailConfigs(newNotifcationEmailConfigs);
    }

    let addNotificationConfig = _ => {
        setNotificationEmailConfigs(notificationEmailConfigs.concat([{email: '', notificationTypes: {}}]))
    }

    let deleteNotificationConfig = idx => {
        let newNotificationSettings = [...notificationEmailConfigs];
        newNotificationSettings.splice(idx, 1);
        setNotificationEmailConfigs(newNotificationSettings);
    }

    const saveNotificationSettings = () => {
        setDisableSubmit(true);
        let submittableNotificationConfigs = notificationEmailConfigs.filter(n => n && n.email && n.notificationTypes && NOTIFICATION_TYPES.reduce((hasOne, type) => hasOne || n.notificationTypes[type], false));
        if (sendNotificationEmails && !submittableNotificationConfigs.length) return toast.error('Please add at least one valid notification type!');
        axios.put(NOTIFICATION_SETTINGS_URL, { sendNotificationEmails, notificationEmailConfigs: submittableNotificationConfigs})
            .then(_ => {
                toast.success('Notification Settings Saved!')
                refreshCurrentShopSettings()
            })
            .catch(e => {
                console.error(e)
                refreshCurrentShopSettings()
            })
            .then(_ => setDisableSubmit(false));
    };

    return (
        <Container>
            <h1>Notification Settings</h1>
            <Form>
                <Form.Group as={Form.Row}>
                    <Form.Label className="pr-1 font-weight-bold">Send Notification Emails</Form.Label>
                    <Form.Check inline type="switch" id="sendNotificationEmails" checked={sendNotificationEmails} onChange={e => setSendNotificationEmails(e.target.checked)}/>
                </Form.Group>
                {
                    sendNotificationEmails && notificationEmailConfigs.map((notificationConfig, idx) => <div key={idx}>
                        <hr/> 
                        <Form.Row>
                            <Col>
                                <Form.Group as={Form.Row} className='py-0 my-0'>
                                    <Form.Label column md="2" sm='3'>Email: </Form.Label>
                                    <Col md="10" sm="9">
                                        <Form.Control required value={notificationConfig.email || ''} onChange={e => updateNotificationEmail(idx, e.target.value)} />
                                    </Col>
                                </Form.Group>
                            </Col>
                            {
                                NOTIFICATION_TYPES.map(type => (
                                    <Col key={type+idx}>
                                        <Form.Switch label={type} id={type+idx} inline checked={notificationConfig.notificationTypes[type]} onChange={e => updateNotificationType(idx, type, e.target.checked)} />
                                    </Col>
                                ))
                            }
                            <Col sm="1">
                                <Button variant="danger" onClick={_ => deleteNotificationConfig(idx)}>
                                    <FontAwesomeIcon size='sm' icon={faTrash} />
                                </Button>
                            </Col>
                        </Form.Row>
                    </div>)
                }
                { sendNotificationEmails &&
                    <Button variant='secondary' className="mb-5 mt-4" block onClick={addNotificationConfig}>
                        <FontAwesomeIcon size='sm' icon={faPlus} />
                    </Button>
                }
                <Navbar sticky='bottom' bg='light'>
                    <Button disabled={disableSubmit} variant="primary" onClick={saveNotificationSettings}>Save changes</Button>
                </Navbar>
            </Form>
        </Container>
    )
}
