import React, { useState } from 'react';
import { DateTime } from 'luxon';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import groupSalesByProductId from '../sales/salesGroupByProductID';

export default function OrderModal({ order }) {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function conditionalRenderDiscount({ order }) {
    if (order.discount && order.discount !== 0) {
      return (
        <Row className='mt-2'>
          <Col className='pt-0 pl-0' >Discount</Col>
          <Col className='pt-0 pr-4 font-weight-bold'>
            <p className='mb-0 pr-2 pull-right text-success'> - {(order.discount).toFixed(2)}</p>
          </Col>
        </Row>
      )
    }
  }

  /**
   * Renders the tip amount if any
   * 
   * @param {number} tipAmount 
   * @returns the tip amount row
   */
  function conditionalRenderTipAmount(tipAmount) {
    if (tipAmount) {
      return (
        <Row className='mt-2'>
          <Col className='pt-0 pl-0' >Tip</Col>
          <Col className='pt-0 pr-4 font-weight-bold'>
            <p className='mb-0 pr-2 pull-right text-danger'> + {(tipAmount).toFixed(2)}</p>
          </Col>
        </Row>
      )
    }
  }

  function conditionalRenderCardFee(cardFee) {
    if (cardFee) {
      return (
        <Row className='mt-2'>
          <Col className='pt-0 pl-0' >Card Fee</Col>
          <Col className='pt-0 pr-4 font-weight-bold'>
            <p className='mb-0 pr-2 pull-right text-danger'> + {(cardFee).toFixed(2)}</p>
          </Col>
        </Row>
      )
    }
  }

  function expandTaxes(taxes) {
    taxes = Object.values(taxes)

    return taxes.map((tax, index) => (
        <Row key={tax.name} className='mt-2'>
          <Col className='pt-0 pl-0'>{tax.name} Tax</Col>
          <Col className='pt-0 pr-4 font-weight-bold'>
            <p className='mb-0 pr-2 pull-right'>{(+tax.amount).toFixed(2)}</p>
          </Col>
        </Row>
    ))}

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Order Details
      </Button>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Order -
            { order.canceled && <span className="text-danger"> Canceled</span>}
            { order.done && <span className="text-success"> Vended</span>}
            { !order.done && !order.canceled && <span className="text-warning"> In Progress</span>}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className='mt-0' >
              <Col className='pt-0'>
                <Row className='mt-2'>
                  <Col lg={8} className='pt-0 pr-4'>
                    <p className='mb-0 pr-2 font-weight-bold'>{(order.customer.name)}</p>
                    <p className='mb-0'>{order.customer.email}</p>
                    <p>{order.customer.cell}</p>
                    <p className='mb-0 font-weight-bold'>{DateTime.fromISO(order.timestamp).toLocaleString(DateTime.DATETIME_FULL)} @ {order.terminalId ? `Side ${order.terminalId.split('-t')[1]}` : 'Unknown'}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Table striped bordered hover variant className='mb-0'>
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {(groupSalesByProductId(order)).map((order, index) => (
                  <tr key={order._id}>
                    <td>{order.name}</td>
                    <td>{order.quantity}</td>
                    <td>{order.price.toFixed(2)}</td>
                    <td>{(order.quantity * order.price).toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Row className='mt-0' >
              <Col className='pt-0' />
              <Col className='pt-0' lg={4}>
                <Row className='mt-2'>
                  <Col className='pt-0 pl-0' >Sub Total</Col>
                  <Col className='pt-0 pr-4 font-weight-bold'>
                    <p className='mb-0 pr-2 pull-right'>{(order.subTotal).toFixed(2)}</p>
                  </Col>
                </Row>
                {conditionalRenderDiscount({ order })}
                {expandTaxes(order.taxes)}
                <Row className='mt-2 pt-2 pb-2' style={{ backgroundColor: "#f7f7f7" }}>
                  <Col className='pt-0 pl-0'>TOTAL</Col>
                  <Col className='pt-0 pr-4 font-weight-bold'>
                    <p className='mb-0 pr-2 pull-right'>$ {(order.total).toFixed(2)}</p>
                  </Col>
                </Row>
                {conditionalRenderCardFee(order.cardFee)}
                {conditionalRenderTipAmount(order.tipAmount)}
                <Row className='mt-2 pt-2 pb-2'>
                  <Col className='pt-0 pl-0'>Paid ({order.paymentType})</Col>
                  <Col className='pt-0 pr-4 font-weight-bold'>
                    <p className='mb-0 pr-2 pull-right text-success'>$ {(order.amountPaid).toFixed(2)}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
