import React from 'react';
import styled from 'styled-components';

export default function NoSales({message}) {
    return <NoSalesH2>{message}</NoSalesH2>;
}

const NoSalesH2 = styled.h2`
    width: 100%;
    font-size: 44px;
    margin-top: 100px;
    margin-bottom: 100px;
    @media(max-width: 767px) {
        margin-left: 0;
        margin-top: 20;
    }

`;
