export default function getSalesInfoByTimeInterval(ordersQueryParameters, ordersAggregated) {

    function getHourInterval () {
        const timeInterval = {};
        for (let i = 6; i < 23; i++) {
            timeInterval[i] = 0;
        }
        return timeInterval
    }

    function getDateInterval(startDate, endDate) {
        let dates = {},
        currentDate = startDate,
        addDays = function(days) {
            let date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        };
        while (currentDate <= endDate) {
            dates[currentDate.toISOString().substring(0, 10)] = 0;
            currentDate = addDays.call(currentDate, 1);
        }
        return dates;
    };

    if ((ordersQueryParameters.startDate).slice(0, 10) === (ordersQueryParameters.stopDate).slice(0, 10)) {
        let aggregation = getHourInterval(); 

        ordersAggregated.forEach(function(aggregate) {
            aggregation[aggregate.date] = [aggregate.totalSales, aggregate.countSales]
        })

        return Object.keys(aggregation).map((date) => {return {date, totalSales: Math.round(aggregation[date][0]*100)/100, countSales:aggregation[date][1]}})
    } else {
        let aggregation = getDateInterval(new Date(ordersQueryParameters.startDate), new Date(ordersQueryParameters.stopDate)); 
        
        ordersAggregated.forEach(function(aggregate) {
            aggregation[String(aggregate.date)] = [aggregate.totalSales, aggregate.countSales]
        })

        return Object.keys(aggregation).map((date) => {return {date, totalSales: Math.round(aggregation[date][0]*100)/100, countSales : aggregation[date][1]}})
    }


    
    
}