import React, {useCallback, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import styled from 'styled-components';
import { CirclePicker } from 'react-color';


const CATEGORY_URL = '/category';

const COLORS = ["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b"];

export default function EditCategoryModal({hide, afterSave, categoryIn}) {

    const [category, setCategory] = useState(categoryIn);
    const [disabled, setDisabled] = useState(false);

    let updateCategoryLabel = label => {
        let newCategory = {...category};
        newCategory['label'] = label;
        newCategory['key'] = label.toLowerCase().replace(/\s+/g, '-');
        setCategory(newCategory);
    }

    const saveCategory = useCallback(_ => {
        axios.put(CATEGORY_URL, category).then(({data}) => {
            setDisabled(false)
            afterSave(data);
            hide();
        }).catch(error => error);
    }, [afterSave, hide, category]);

    return (
        <Modal show={true} className='categoryModal' onHide={hide}>
            <ModalContainer>
                <Modal.Header closeButton>
                    <Modal.Title>{category._id ? 'Edit' : 'Create'} Category:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={e => console.log(e.currentTarget.value)}>
                        <Form.Group controlId="label">
                            <Form.Label>Label</Form.Label>
                            <Form.Control type="text" placeholder="label" value={category.label || ''} onChange={e=>updateCategoryLabel(e.target.value)}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Color</Form.Label>
                            <CirclePicker style={{ width: '100%', }} colors={COLORS} color={category.color || COLORS[0]} onChangeComplete={color=>setCategory({...category, color : color.hex})}/>
                        </Form.Group>
                        <Form.Group controlId="key">
                            <Form.Label>Key</Form.Label>
                            <Form.Control disabled type="text" placeholder="key" value={category.key || ''}/>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={hide}>Cancel</Button>
                    <Button variant="primary" onClick={() => {saveCategory(); setDisabled(true);}} disabled={disabled}>Save changes</Button>
                </Modal.Footer>
            </ModalContainer>
        </Modal>
    )
}

const ModalContainer = styled.div`
    .circle-picker {
        width: 100% !important;
    }
`;
