import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import PackagesTable from './packagesTable';
import usePackages from '../hooks/usePackages';
import Loading from './loading';

export default function Packages(props) {

    const [selectedTab, setSelectedTab] = useState('incoming');
    const [packages, refreshPackageData] = usePackages(selectedTab);

    let permissions = props.permissions;

    return (
        <>
            { permissions && (permissions.some(per => per === 'packages') || permissions.some(per => per === 'admin')) ?
                <div>
                    {packages && packages.length > 1 ?
                        <div>
                            <Nav variant="tabs" defaultActiveKey='incoming' className="pt-3" onSelect={tabKey => setSelectedTab(tabKey)}>
                                <Nav.Item>
                                    <Nav.Link eventKey="all"> All Packages </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="incoming"> Incoming Packages </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="active"> Active Packages </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="empty"> Empty Packages </Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <PackagesTable packages={packages} refresh={refreshPackageData} />
                        </div>
                        :
                        <Loading />
                    }
                </div>
            :
                <h1 style={{marginTop: '30px',}}>You do not have access to packages</h1>
            }
        </>
    )
}
