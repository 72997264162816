import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import axios from 'axios';
import { toast } from 'react-toastify';
import Autocomplete from "react-google-autocomplete";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import Navbar from 'react-bootstrap/esm/Navbar';

const GOOGLE_MAPS_API_KEY = 'AIzaSyBydpvlhAOo9yY9vXM4cOn0ug8l-5GWSns'

const SHOP_INFO_URL = '/settings/info';

const states = ['AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'];

export default function ShopInfoSettings({currentShopSettings, refreshCurrentShopSettings}) {

    const [shopInfo, setShopInfo] = useState(currentShopSettings);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [invalidFields, setInvalidFields] = useState({});

    useEffect(() => {
        setShopInfo(currentShopSettings);
    }, [currentShopSettings]);

    let updateShopInfo = (key, value) => {
        setShopInfo({ ...shopInfo, [key]: value });
    }
 
    const saveShopInfo = () => {
        setDisableSubmit(true);
        axios.put(SHOP_INFO_URL, shopInfo)
            .then(_ => {
                toast.success('Shop Info Setting Saved!')
                setInvalidFields({})
                refreshCurrentShopSettings()
            })
            .catch(e => {
                console.error(e)
                setInvalidFields({...invalidFields, [e.response?.data?.field]: e.response?.data?.msg })
                toast.error(e.response?.data?.msg)
                refreshCurrentShopSettings()
            })
            .then(_ => setDisableSubmit(false));
    };

    return (
        <Container>
            <h1 className='my-3'>Shop Settings</h1>
            <Form>
                <Form.Group controlId="name">
                    <Form.Label>Shop Name</Form.Label>
                    <Form.Control isInvalid={invalidFields['name']} required type="text" placeholder="Shop Name" value={shopInfo.name} onChange={e => updateShopInfo(e.target.id, e.target.value)} />
                </Form.Group>
                <Form.Group controlId="address">
                    <Form.Label>Street Address</Form.Label>
                    <Autocomplete   className='form-control' required type="text" placeholder="Shop Address" value={shopInfo.address} onChange={e => updateShopInfo('address', e.target.value)}
                                    apiKey={GOOGLE_MAPS_API_KEY}
                                    options={{types: []}}
                                    inputAutocompleteValue="route"
                                    onPlaceSelected={(place) => {
                                        console.log(place);
                                        let location = {
                                            pretty: place.formatted_address,
                                            lat: place.geometry.location.lat(),
                                            lng: place.geometry.location.lng(),
                                            googlePlaceId: place.place_id
                                        };
                                        place.address_components.forEach(component => {
                                            if (component.types.includes('street_number')) location.streetNumber = component.long_name;
                                            else if (component.types.includes('route')) location.street = component.long_name;
                                            else if (component.types.includes('neighborhood')) location.neighborhood = component.long_name;
                                            else if (component.types.includes('locality')) location.city = component.long_name;
                                            else if (component.types.includes('administrative_area_level_2')) location.county = component.long_name;
                                            else if (component.types.includes('administrative_area_level_1')) location.state = component.long_name;
                                            else if (component.types.includes('country')) location.country = component.long_name;
                                            else if (component.types.includes('postal_code')) location.zip = component.long_name;
                                            else if (component.types.includes('country')) location.country = component.long_name;
                                        })
                                        setShopInfo({ ...shopInfo, location, address: `${location.streetNumber} ${location.street}`, ...location })
                                    }}/>
                </Form.Group>
                <Form.Row>
                    <Form.Group as={Col} controlId="city">
                        <Form.Label>City</Form.Label>
                        <Form.Control isInvalid={invalidFields['city']} value={shopInfo.city} onChange={e => updateShopInfo(e.target.id, e.target.value)} />
                    </Form.Group>

                    <Form.Group className="state" as={Col} controlId="state">
                        <Form.Label>State</Form.Label>
                        <Form.Control isInvalid={invalidFields['state']} as="select" value={shopInfo.state} onChange={e => updateShopInfo(e.target.id, e.target.value)}>
                            <option>{shopInfo.state ? shopInfo.state : '---'}</option>
                            { states.map(state => <option key={state} value={state}>{state}</option>) }
                        </Form.Control>
                    </Form.Group>

                    <Form.Group as={Col} controlId="zip">
                        <Form.Label>Zip</Form.Label>
                        <Form.Control isInvalid={invalidFields['zip']} value={shopInfo.zip} onChange={e => updateShopInfo(e.target.id, e.target.value)} />
                    </Form.Group>
                </Form.Row>
                <Form.Group controlId="website">
                    <Form.Label>Website</Form.Label>
                    <Form.Control isInvalid={invalidFields['website']} type="text" placeholder="Shop Url" value={shopInfo.website} onChange={e => updateShopInfo(e.target.id, e.target.value)} />
                </Form.Group>
                <Form.Group controlId="licenseNumber">
                    <Form.Label>License Number</Form.Label>
                    <Form.Control isInvalid={invalidFields['licenseNumber']} type="text" placeholder="License #" value={shopInfo.licenseNumber} onChange={e => updateShopInfo(e.target.id, e.target.value)} />
                </Form.Group>
                <Form.Group controlId="phoneNumber">
                    <Form.Label>Phone Number</Form.Label>
                    <PhoneInput buttonStyle={{zIndex: 900}} inputStyle={{width: "100%"}} containerClass='input-group' inputClass='form-control' country="us" disableDropdown={true} countryCodeEditable={false} value={shopInfo.phoneNumber} onChange={phoneNumber => updateShopInfo("phoneNumber", phoneNumber)} />
                </Form.Group>
                
            </Form>
            <Navbar sticky='bottom' bg='dark'>
                <Button disabled={disableSubmit} variant="primary" onClick={saveShopInfo}>Save changes</Button>
            </Navbar>
        </Container>
    )
}

const Container = styled.div`
    justify-content: center;
    margin: 0 auto;
    padding-bottom: 30px;
    .form-group {
        .input-group {
            width: 60%;
            display: inline-block;
        }
        .input-group-append {
            width: auto;
            display: inline-block;
        }
    }
    .form-group label{
        width: 20% !important;
        display: inline-block;
        text-align: left;
        font-weight: bold;
    }
    .form-group input{
        width: 60%;
        display: inline-block;
    }
    .form-group select{
        width: 60%;
        display: inline-block;
    }
    .form-row {
        width: 80%;
        margin: 0 auto;
        justify-content: space-between;
        .col {
            width: auto;
            flex-basis: auto;
            flex-grow: 0;
            text-align: left;
            padding-left: 0;
            padding-right: 0;
            input {
                width: 80%;
            }
        }
        .state {
            select {
                width: 80%;
                display: inline-block;
            }
            width: 40%;
        }
    }
    .form-check {
        color: #000000;
        margin-bottom: 15px;
        margin-right: 15px;
        input {
            position: relative;
            width: auto;
        }
        label {
            width: auto !important;
        }
    }
`;
