import React, { useState } from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Accordion from 'react-bootstrap/Accordion';
import EditProductModal from './modals/editProductModal';
import ConfirmDeleteModal from './modals/confirmDeleteModal';
import useCategories from '../hooks/useCategories';
import useProducts from '../hooks/useProducts';
import EditCategoryModal from './modals/editCategoryModal';
import Loading from './loading';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import logo from '../stopsign-logo.png';
import { DateTime } from 'luxon';

const PRODUCT_URL = '/product';


export default function Products({ permissions }) {

    const [categories, reloadCategories] = useCategories();

    const [editingCategory, setEditingCategory] = useState(null);

    const [editingProduct, setEditingProduct] = useState(null);

    const [productToDelete, setProductToDelete] = useState(null);

    const [products, _reloadProducts] = useProducts('all');
    const reloadProducts = _ => setEditingProduct(null) || setProductToDelete(null) || _reloadProducts();

    const [open, setOpen] = useState(false);

    const [accordion, setAccordion] = useState([]);

    if (!products || !categories || !permissions) return <Loading />

    const toggle = id => {
        if (accordion.includes(id)) {
            setAccordion(accordion.filter(item => item !== id));
        } else {
            setAccordion(accordion => [...accordion, id])
        }
        setOpen(id === open ? null : id);
    }

    const deleteProduct = productToDelete => {
        console.log('deleting product: ', productToDelete);
        axios.delete(PRODUCT_URL + '/' + productToDelete._id).then(reloadProducts).catch(error => error);
    };

    let CategoryRows = categories.map((category, i) =>
        <Accordion key={category.key} defaultActiveKey="0">
            <CardContainer>
                <Card style={{ border: 'none', borderRadius: 0 }}>
                    <Card.Header style={{ backgroundColor: category.color, border: 'none', }}>
                        <ButtonDiv>
                            <Button style={{ backgroundColor: 'transparent', border: 'none' }} className='float-left' onClick={() => setEditingProduct({ category: category.key, name: '', price: '', description: '', img: '' })}>Add Product</Button>
                            <Button style={{ marginLeft: '10px', verticalAlign: 'middle', backgroundColor: 'transparent', border: 'none' }} className="float-left" onClick={_ => setEditingCategory(category)}>Edit Category</Button>
                        </ButtonDiv>
                        <Accordion.Toggle as={AccordionDiv} variant="link" eventKey="0" className="text-light" onClick={(e) => toggle(category.key)}>
                            <h2>{category.label}</h2>
                            <Button style={{ marginLeft: '10px', backgroundColor: 'transparent', border: 'none' }} className="float-right">{accordion.includes(category.key) ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}</Button>
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        {!products.isEmpty ?
                            <CardBlock>
                                <CardBody>
                                    <Row>
                                        {products.filter(p => p.category === category.key).map((p, i) => {
                                            return (
                                                <>
                                                    <Col xs={12} sm={6} md={6} lg={4} key={p._id}>
                                                        <Card className="product-item">
                                                            <ImageContainer>
                                                                {p.cannabisType === "sativa" ?
                                                                    <Dot style={{ border: '3px solid #FF722C', backgroundColor: '#FF722C', }}>
                                                                        <p>S</p>
                                                                    </Dot>
                                                                    : p.cannabisType === "indica" ?
                                                                        <Dot style={{ border: '3px solid #922CFF', backgroundColor: '#922CFF', }}>
                                                                            <p>I</p>
                                                                        </Dot>
                                                                        : p.cannabisType === "hybrid" ?
                                                                            <Dot style={{ border: '3px solid #02E81A', backgroundColor: '#02E81A', }}>
                                                                                <p>H</p>
                                                                            </Dot>
                                                                            :
                                                                            null
                                                                }
                                                                {p.img ?
                                                                    <Image src={p.img} />
                                                                    :
                                                                    <ImageLogo src={logo} />
                                                                }
                                                            </ImageContainer>
                                                            <Card.Body>
                                                                <CardText style={{ fontWeight: 'bold', maxHeight: '52px', }}>{ p.name }</CardText>
                                                                {p.description ?
                                                                    <Accordion>
                                                                        <Accordion.Toggle as={AccordionDiv} variant="link" eventKey="0" className="text-light" onClick={(e) => toggle(category.key)}>
                                                                            <Card.Text className="description">Description</Card.Text>
                                                                        </Accordion.Toggle>
                                                                        <Accordion.Collapse eventKey="0">
                                                                            <Card.Text style={{ height: '170px', overflowY: 'scroll', }}>{p.description}</Card.Text>
                                                                        </Accordion.Collapse>
                                                                    </Accordion>
                                                                    :
                                                                    null
                                                                }
                                                                {p.thc || p.cbd || p.cbda || p.cbn || p.cbg || p.cbc || p.thcv || p.cbdv ?
                                                                    <Accordion>
                                                                        <Accordion.Toggle as={AccordionDiv} variant="link" eventKey="0" className="text-light" onClick={(e) => toggle(category.key)}>
                                                                            <Card.Text className="description">Testing Info</Card.Text>
                                                                        </Accordion.Toggle>
                                                                        <Accordion.Collapse eventKey="0">
                                                                            <TestingInfo>
                                                                                <ul>
                                                                                    {p.thc &&
                                                                                        <li className="clearfix">
                                                                                            <p>THC</p>
                                                                                            <p>{p.thc}%</p>
                                                                                        </li>
                                                                                    }
                                                                                    {p.cbda &&
                                                                                        <li className="clearfix">
                                                                                            <p>CBDA</p>
                                                                                            <p>{p.cbda}%</p>
                                                                                        </li>
                                                                                    }
                                                                                    {p.cbd &&
                                                                                        <li className="clearfix">
                                                                                            <p>CBD</p>
                                                                                            <p>{p.cbd}%</p>
                                                                                        </li>
                                                                                    }
                                                                                    {p.cbn &&
                                                                                        <li className="clearfix">
                                                                                            <p>CBN</p>
                                                                                            <p>{p.cbn}%%</p>
                                                                                        </li>
                                                                                    }
                                                                                    {p.cbg &&
                                                                                        <li className="clearfix">
                                                                                            <p>CBG</p>
                                                                                            <p>{p.cbg}%%</p>
                                                                                        </li>
                                                                                    }
                                                                                    {p.cbc &&
                                                                                        <li className="clearfix">
                                                                                            <p>CBC</p>
                                                                                            <p>{p.cbc}%%</p>
                                                                                        </li>
                                                                                    }
                                                                                    {p.thcv &&
                                                                                        <li className="clearfix">
                                                                                            <p>THCV</p>
                                                                                            <p>{p.thcv}%%</p>
                                                                                        </li>
                                                                                    }
                                                                                    {p.cbdv &&
                                                                                        <li className="clearfix">
                                                                                            <p>CBDV</p>
                                                                                            <p>{p.cbdv}%%</p>
                                                                                        </li>
                                                                                    }
                                                                                    {p.dateTested &&
                                                                                        <li className="clearfix">
                                                                                            <p>Date Tested</p>
                                                                                            <p>{DateTime.fromJSDate(p.dateTested).toLocaleString(DateTime.DATE_SHORT)}</p>
                                                                                        </li>
                                                                                    }
                                                                                </ul>
                                                                            </TestingInfo>
                                                                        </Accordion.Collapse>
                                                                    </Accordion>
                                                                    :
                                                                    null
                                                                }
                                                            </Card.Body>
                                                            <Card.Footer>
                                                                <Button style={{ width: '40%' }} className="float-left" variant="outline-danger" onClick={() => setProductToDelete(p)}>Delete</Button>
                                                                <Button style={{ width: '40%' }} className="float-right" onClick={() => setEditingProduct(p)}>Edit</Button>
                                                            </Card.Footer>
                                                        </Card>
                                                    </Col>
                                                </>
                                            )
                                        }
                                        )}
                                    </Row>
                                </CardBody>
                            </CardBlock>
                            :
                            <NoView>No {category.key} product</NoView>
                        }
                    </Accordion.Collapse>
                </Card>
            </CardContainer>
        </Accordion>
    )

    return (
        <>
            {permissions && (permissions.some(per => per === 'products') || permissions.some(per => per === 'admin')) ?
                <div>
                    {CategoryRows}
                    {
                        editingCategory ?
                            <EditCategoryModal categoryIn={editingCategory}
                                hide={() => setEditingCategory(null)}
                                afterSave={reloadCategories} /> : null
                    }
                    {
                        editingProduct ?
                            <EditProductModal productIn={editingProduct}
                                hide={() => setEditingProduct(null)}
                                afterSave={reloadProducts} /> : null
                    }
                    {
                        productToDelete ?
                            <ConfirmDeleteModal name={productToDelete.name}
                                yes={() => deleteProduct(productToDelete)}
                                no={() => setProductToDelete(null)} /> : null
                    }
                </div>
                :
                <h1 style={{ marginTop: '30px', }}>You do not have access to products</h1>
            }
        </>
    )
}

const NoView = styled.h2`
    font-weight: bold;
    font-size: 48px;
    margin-top: 3%;
    margin-bottom: 3%;
    text-align: center;
    width: 100%;
`;

const CardContainer = styled.div`
    .card-header:first-child {
        border-radius: 0;
    }
    .card-header {
        .text-light:hover {
            cursor: pointer;
        }
      }
`;

const TestingInfo = styled.div`
  ul {
    list-style-type: none;
    padding-left: 0;
    li {
      display: flex;
      justify-content: space-between;
      p {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
`;

const CardBlock = styled.div`
  padding: 0.2em;
  margin-bottom: 30px;
  border: none;
  height: auto;
  .product-item {
    border: none;
    box-shadow: 0 0 3px rgba(0,0,0,0.4);
  }
  .card-header {
    .text-light:hover {
        transform: scale(1.1);
        cursor: pointer;
    }
  }
  .card-body {
    height: auto;
    .description {
      color: #26A65B;
    }
    .description:hover {
      cursor: pointer;
    }

  }
  .card-footer{
    border: none;
  }
  button.btn-primary:hover {
    background-color: #FFFFFF;
    color: #2ECC71;
  }
  .row {
    margin-top: 10px;
  }
`;

const CardBody = styled.div`
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0 1.25rem;
`;

const ImageContainer = styled.div`
    height: 225px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    background: rgb(193, 193, 193, .2);
`;

const Image = styled.img`
    object-fit: cover;
    height: 100%;
    min-width: 100%;
`;

const ImageLogo = styled.img`
    object-fit: contain;
    transform: scale(.9);
    height: 100%;
    min-width: 100%;
`;


const CardText = styled.h2`
    font-weight: bold;
    font-size: 24px;
`;

const ButtonDiv = styled.div`

`;

const AccordionDiv = styled.div`
    h2 {
        display: inline-block;
        margin-left: -10%;
        margin-bottom: 0;
    }
    @media (max-width: 450px) {
      h2 {
        margin-left: 0%;
      }
    }

`;

const Dot = styled.div`
  p {
      height: 100%;
      width: 100%;
      margin-bottom: 0;
      text-align: center;
      display: inline-block;
      vertical-align: top;
      margin-top: -2px;
      color: #FFFFFF;
      font-size: 14px;
      font-weight: bold;
  }
  height: 25px;
  width: 25px;
  display: inline-block;
  position: absolute;
  z-index:900;
  bottom: 0px;
  right: 0px;
  border-top-left-radius: 50%;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;
